@import '../../css/variable.less';

.com-picker {

    position:relative;
    color:#333;

    .picker-overlay {
        position:fixed; width:100%; height:100%;
        top:0; left:0;
        background-color:@overlay-grey;
        display:none;
    }

    .picker-label {
        display:flex; align-items:center;
        position:relative; cursor:pointer;
        padding:0 28px 0 10px; height:34px; min-width:130px;
        font-size:15px; line-height:1;
        background-color:@bg-picker-label; border-radius:3px;
    }
    .picker-label.disabled {
        cursor:default; padding-right:10px;
        background-color:@bg-picker-label-disabled;
    }

    .picker-label-txt {
        pointer-events:none; user-select:none;
    }

    .picker-label svg {
        width:14px; height:14px;
        position:absolute; right:7px; top:50%; margin-top:-7px;
        fill:#333; pointer-events:none;
    }

    .picker-content {
        position:relative; z-index:1001;
        width:750px; margin-left:-375px;
        margin-top:3px; position:fixed; left:50%;
        background-color:#f6f5f1;
        box-shadow:0 0 6px rgba(0,0,0,.25);
        border-radius:2px;
        display:none;
    }

    .filter {
        padding:10px; position:relative;

        input {
            width:100%; height:36px !important; padding-left:32px;
            box-sizing:border-box;
            color:#444; font-size:15px !important;
            background-color:#e9e9e5; border-radius:2px;
        }

        .icon-search {
            width:16px; height:16px; fill:#444;
            position:absolute; left:17px; top:50%; margin-top:-8px;
            pointer-events:none;
        }
    }

    .picker-dropdown {
        overflow-y:auto;
        max-height:calc(100vh - 240px); // TEST
    }

    .menu-wrapper {
        display:flex; justify-content:space-between;
        padding:0 10px;
    }

    .menu-slot+.menu-slot {margin-left:14px;}

    .menu-slot {

        flex:1; position:relative;

        .group-title {
            display:flex; align-items:center; justify-content:space-between;
            position:sticky; top:0; z-index:200;
            height:36px; padding:0 10px; margin-bottom:8px;
            font-size:16px; font-weight:normal; color:#333;
            white-space:nowrap;
            background-color:#fff;
            border-radius:0; border-bottom:2px solid #dadada;
            overflow:hidden; cursor:pointer;

            span {pointer-events:none;}
        }
        .group-title:hover {background-color:#f9f9f9;}

        .group-title .length {font-size:14px;}

        ul {padding-bottom:8px;}
        li {cursor:pointer}

        li.hide {display:none;}

        .menu-item {
            position:relative;
            display:flex; align-items:center;
            box-sizing:border-box; overflow:hidden;
            height:38px; padding:0 10px;
            color:#444; font-size:14px;
            white-space:nowrap;
            border-radius:2px; border-bottom:1px solid #ddd;
            pointer-events:none;
        }
        .menu-item:before {
            content:""; display:block; width:6px; height:6px;
            margin-right:7px; flex-shrink:0;
            background-color:#444; border-radius:50%;
            pointer-events:none;
        }

        .type-2.menu-item {padding-left:6px;}
        .type-2.menu-item:before {
            content:"D"; width:20px; height:20px;
            display:flex; justify-content:center; align-items:center;
            font-size:13px; line-height:1; color:#444;
            background-color:#ddd; border-radius:2px;
        }

        li:hover .menu-item {background-color:#008398; color:#fff;}
        li:hover .menu-item:before {background-color:#fff;}

        .menu-item:after {
            content:attr(data-id); position:absolute; right:0px;
            min-width:24px; padding:0 2px; line-height:1.6;
            font-size:12px; text-align:center;
            background-color:#fff; border-radius:2px;
        }
        li:hover .menu-item:after {background-color:#379bac;}

    }

    .picker-content.col-2 {
        // .picker-content 的寬度 -20px (.menu-wrapper 的 padding) -14px (.menu-slo 的 margin-left) - 26px (要多保留一些給瀏覽器捲軸的寬度) / 2
        .menu-slot, .menu-item {width:220px;}
    }

    .resetInput {
        right:20px; background-color:#d3d3d3;
    }
    .resetInput:hover {
        background-color:#333;
    }
    .resetInput svg {
        width:14px; height:14px; margin-right:0;
        fill:#333;
    }
    .resetInput:hover svg {fill:#fff;}

}

.com-picker.expand {

    z-index:2000;

    .picker-label {background-color:@bg-picker-label-expand;}

    .picker-overlay, .picker-content {display:block;}

}