// reset slidebox label style
.slidebox .switch-toggle {
    min-width:0; height:32px;
    padding:0; float:none;
    display:flex; margin:0;
}
.slidebox .switch-toggle.medium {
    height:26px;
}

// 預設尺寸
.switch-toggle {

    display:flex; align-items:center;
    width:54px; height:32px;

    input {display:none;}

    .trigger, i {
        transition:all 150ms ease;     
        cursor:pointer;
    }

    .trigger {  
        position:relative; width:100%; height:100%;   
        border-radius:100px;
        background-color:rgba(0,0,0,.05);
        box-shadow:inset 0 0 0 1px rgba(0,0,0,.05);
        overflow:hidden;
    }

    i {
        display:block; width:28px; height:28px;
        position:absolute; left:2px; top:2px;        
        background-color:#fff; border-radius:100px;
        box-shadow:0 0 4px rgba(0,0,0,.2); 
        pointer-events:none;
    }

    .trigger:hover i {  
        box-shadow:0 0 4px rgba(0,0,0,.3); 
        transform:scale(1.01);
    }

    input:checked + .trigger i {
        left:24px;
    }

    .trigger:active {
        background-color:#a6b9cb;     
    }

    // 按下去的一瞬間，讓圓球會稍微拉長一些的動態效果
    .trigger:active i {
        width:34px;
        box-shadow:0 0 4px rgba(0,0,0,.3); 
    }

    input:checked + .trigger:active i {
        left:18px;
    }

    input:checked + .trigger {
        background-color:#249eab;
    }

}

// 中等尺寸的樣式
.switch-toggle.medium {

    // transform: scale(.9);

    width:44px; height:26px;

    i {
        width:22px; height:22px;
    }

    input:checked + .trigger i {
        left:20px;
    }

    .trigger:active i {
        width:26px;    
    }

    input:checked + .trigger:active i {
        left:16px;
    }

}