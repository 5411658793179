@import '../../css/variable.less';
@import '../deviceSum/deviceSum.less';

#deviceUsageReportBox {

    .dialog-container {
        max-width:1700px; height:calc(100% - 20px); max-height:calc(100% - 20px);
        padding:0;
        // background-color:#dfdfdf;
    }

    .content.no-scroll {        
        overflow:hidden !important;
    }

    .innerwpr {
        padding:0; display:flex; flex-direction:column;
        // overflow-y:auto;
    }

    // 關閉按鈕列
    .btnwpr {padding:15px 20px; margin-top:0;}
    // #exportDeviceUsageReportBox {
    //     background-color:#fff; color:#333;
    // }
    // #exportDeviceUsageReportBox:hover {
    //     background-color:#008398; color:#fff;
    // }

    .title {   
        height:50px; margin:0; box-sizing:border-box;  
    }
    // .title svg {margin-right:0;}

    .control-menu {
        flex-grow:1;
        display:flex; align-items:center; justify-content:center;
    }

    .picker+.picker {margin-left:10px;}

    // 開始查詢按鈕
    #durQuerySubmit {
        width:140px; height:34px; margin-left:20px;
        display:flex; align-items:center; justify-content:center;
        font-size:15px; color:#fff;
        background-color:@btn-black-teal; border-radius:20px;
        cursor:pointer;
    }
    #durQuerySubmit:hover {
        background-color:@btn-black-teal-hover;
    }

    .empty-hint {     

        .content {
            display:inline-block; padding:25px; margin-bottom:0;
            text-align:center; font-size:15px;
            // background-color:#eee; border-radius:4px;
            // box-shadow:0 0 0 1px #e8e8e5;
        }

        h4 {font-size:19px; margin-bottom:5px;}
        p {text-align:center; display:block;}

    }

    // 摘要
    .sumPanel {

        padding:10px 20px; position:sticky; top:0;     
        // background-color:#dfdfdf;

        .info {
            display:flex; align-items:center; justify-content:space-between;
            margin-bottom:8px;

            .group {display:flex; align-items:center;}

            .tag {
                padding:6px; margin-right:6px; min-width:26px;
                font-size:14px; text-align:center; line-height:1;
                white-space:nowrap;
                background-color:#f6f6f6; border-radius:2px;
            }
            .tag.type {background-color:#ffdc98;}

            h4 {
                margin-left:6px; position:relative; top:-1px;
                font-size:19px; line-height:1; font-weight:normal;
            }
        }      

    }

    // 報表內容
    .reportPanel {
        position:relative;
        padding:0 20px; overflow-y:auto;

        table {width:100%; min-width:1300px;}

        .device_status,
        .test_count, 
        .device_count_total {text-align:center;}

        tbody {
            // 比較小的文字，日期時間等
            .earliest_time,
            .latest_time,
            .checkin_time,
            .last_updated {font-size:12px;}

            // 長串的文字
            .serial_no,
            .license_key {word-break:break-all;}
        }        

        // 裝置狀態
        .device_status .status {
            padding:3px 6px;    
            border-radius:2px; white-space:nowrap;
        }
        .status-0 .device_status .status {background-color:#666; color:#fff;} // 未啟用 0
        .status-2 .device_status .status {background-color:#fdaea6;} // 備品 2 (SPARE)
        .status-3 .device_status .status {background-color:#ffe2ac;} // 樣品 3 (SAMPLE)
        .status--1 .device_status .status {background-color:#ddd;} // 停用 -1 (DISABLED)
        .status--2 {color:#bbb;} // 已回收 -2 (DELETED)    
        
        .fade {color:#bbb;}
    }

}

// 還沒開始查詢或沒有報表內容時
#deviceUsageReportBox.no-data {

    #exportDeviceUsageReportBox {
        pointer-events:none;
        color:#b3b3b3;
        background-color:#eee;
    }

}