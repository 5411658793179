/* ----- 左下角額外的資訊顯示面板 (例如裝置月報表的裝置轉移紀錄) ----- */
.side-info-box {display:none; position:relative;}
.side-info-box.show {display:block; z-index:9999;}

.side-info-box {

    .overlay {background-color:rgba(0,0,0,.08);}

    .content {
        position:fixed; left:10px; bottom:10px;
        padding:12px; z-index:9998;
        background-color:#fff; border-radius:6px;
        box-shadow:0 0 12px rgba(0,0,0,.3);
    }

    .title {
        margin-bottom:8px; padding-right:28px;
        display:flex; align-items:center;
        line-height:1; color:#2b323b; font-size:16px;

        hr {
            border:none; margin:0; padding:0;
            flex-grow:1;
        }

        .btn {
            min-width:60px; height:28px; padding:0 12px; margin-right:10px;
            display:flex; align-items:center; justify-content:center;
            font-size:14px; line-height:1;
            cursor:pointer;
            background-color:rgba(0, 0, 0, 0.08);
            border-radius:20px;
        }
        .btn:hover {
            color:#fff;
            background-color:#333;
        }
    }

    // 關閉按鈕
    .btn-close {
        position:absolute; right:0; top:0;
        width:38px; height:32px;
        display:flex; justify-content:center; align-items:center;
        cursor:pointer;

        .icon {
            width:18px; height:2px; position:relative; top:2px;
            background-color:#2b323b; border-radius:2px;
            transform:rotate(45deg);
            pointer-events:none;
        }
        .icon:before {
            content:""; display:block; width:2px; height:18px;
            position:absolute; left:50%; top:50%; margin:-9px 0 0 -1px;
            background-color:#2b323b; border-radius:2px;
        }
    }

    .scrollwpr {
        min-width:300px; position:relative;
        min-height:300px; max-height:calc(100vh - 250px);
        overflow-y:auto;
        // overflow:auto;
    }

    .innerwpr {
        max-width:600px; min-width:520px;
        font-size:13px; line-height:1.2;
    }

    p {text-align:left;}
    p+p {margin-top:8px;}

    .box-hint {
        position:absolute; right:10px; top:-28px;
        height:28px; overflow:hidden;
        pointer-events:none;
    }
    .box-hint p {
        padding:3px 8px;
        font-size:14px;
        background-color:#FFDB94; border-radius:4px 4px 0 0;
    }

    .empty-hint {
        width:130px; height:40px;
        left:50%; top:50%; margin:-20px 0 0 -65px;
        display:flex; justify-content:center; align-items:center;
        font-size:14px;
        background-color:#b7f8d3; border-radius:3px;
    }

    table {
        min-width:100%;
        // border-collapse:separate;

        thead th {height:32px; padding:2px 8px;}
        tbody td {
            height:30px; padding:6px 8px;
            border-bottom:1px solid #ddd;

            .disabled {color:#aaa;}
        }

        .active,
        .initial_test_count,
        .timestamp,
        .tested_at {
            text-align:center;
        }
    }

    .table-title {
        margin-bottom:5px; position:relative;
        display:flex; align-items:center; justify-content:center;
        font-size:17px; font-weight:400; color:#333;

        span {
            padding:4px 10px; position:relative;
            background-color:#fff;
        }
    }
    .table-title:before {
        content:""; display:block; width:100%; height:3px;
        position:absolute; left:0; top:50%; margin-top:-1px;
        background-color:#eee;
    }

    table+.table-title {margin-top:15px;}

    .empty-table {
        display:flex; align-items:center; justify-content:center;
        height:50px; margin:10px 0;
        font-size:15px; color:#aaa;
        background-color:#f4f4f4;
    }

}
