@import '../css/variable.less';

/* ---------- 公司跟站點選單 (select option) ---------- */
.panel-selectwpr {
    position:relative; display:flex; height:34px;
    overflow:hidden; min-width:150px;
    background-color:#08728c;
    border-radius:3px;
}
.panel-selectwpr+.panel-selectwpr {margin-left:10px;}
.panel-selectwpr.disabled {
    background-color:#e1e1e1;
    pointer-events:none;
}

.panel-selectwpr {

    // reset select style
    select {
        padding:0 28px 0 10px;
        -webkit-appearance:none; -moz-appearance:none; -ms-appearance:none;
        appearance:none; outline:0; box-shadow:none;
        border:0 !important;
        background-image:none;
    }
    /* Remove IE arrow */
    select::-ms-expand {display:none;}

    // custom select
    select {
        flex:1;
        color:#fff; font-size:15px; line-height:1;
        cursor:pointer;
    }

    option {color:#333;}
    option:disabled {color:#aaa;}

    .icon {
        width:14px; height:14px;
        position:absolute; right:7px; top:50%; margin-top:-7px;
        fill:#fff;
    }

    .single-item {
        display:flex; align-items:center;
        padding:0 20px 0 10px;
    }

}

.panel-selectwpr.single {
    min-width:0;
    background-color:transparent;
}
.panel-selectwpr.single {

    .icon {
        right:0; fill:#333;
    }

}


/* ---------- 站點選單 ---------- */
.sitesMenu {

    select.sites:disabled {
        display:none;
    }

    .single-item {padding-left:0;}

    p.hint {
        height:100%; padding-left:10px; margin:0;
        display:none; align-items:center;
        line-height:1; color:#fff;
    }
}
.sitesMenu.disabled {

    p.hint {display:flex;}
}
// #sitesMenu.cross {
//     background-color:transparent;
//     pointer-events:none;
//     margin-left:5px;
// }
// #sitesMenu.cross:before {
//     content:"（已開啟跨站點模式）"; width:100%; height:100%;
//     display:flex; align-items:center;
// }
// #sitesMenu.cross select {display:none;}
// #sitesMenu.cross svg {display:none;}
// #sitesMenu.cross .single-item {display:none;}


/* ----- 尚未選擇時的提示 ----- */
.empty-hint {
    position:absolute; left:0; top:40%; width:100%;
    text-align:center;
}
.empty-hint {

    .innerwpr {
        display:inline-block; padding:25px;
        line-height:1; font-size:15px;
        background-color:#fff; border-radius:4px;
        box-shadow:0 0 0 1px #e8e8e5;
    }

    h4 {font-size:17px; margin-bottom:12px;}

    p {
        position:relative;
        display:flex; align-items:center; justify-content:center;
        height:20px; line-height:normal;
    }

    svg.hint {
        width:16px; height:16px;
        fill:#333; margin-right:6px;
    }
}

// ----- 插圖空白提示 ----- //
.empty-hint.illustration {
    width:340px;
    position:absolute; left:50%; top:40%;
    margin-left:-170px; margin-top:-100px;

    p {
        margin:10px auto 0 auto; padding:10px 20px;
        display:inline-flex; align-items:center; justify-content:center;
        color:#fafaf8; border-radius:40px;
        background-color:#3f7c98;
    }
    p.error {
        color:#2f333d; font-size:16px;
        background-color:#dedddb;
    }
}
.empty-hint.illustration:before {
    content:""; display:block; width:340px; height:165px;
    background-image:url("/assets/images/illustration/cloud-no-data.png");
    background-size:100% auto; background-repeat:no-repeat;
}


/* ---------- Search box 特定項目的搜尋 ---------- */
#searchBtn {
    display:flex; justify-content:center; align-items:center;
    width:30px; height:30px; padding:0 10px; margin-right:10px;
    background-color:#fafafa; border-radius:3px;
    border:1px solid #d1d1d1; border-radius:3px;
    cursor:pointer;
}
// #searchBtn:hover {
//     background-color:#d7d7d7; border:1px solid #c7c7c7;
// }
#searchBtn.active {
    background-color:#29313d; border:1px solid #222;
}

#searchBtn {
    svg {
        width:16px; height:16px;
        fill:#333;
    }
}
#searchBtn:hover {
    background-color:#fff; border-color:#ccc;
}
#searchBtn.active {
    svg {fill:#fff;}
}


.search-box {
    position:absolute; left:10px; z-index:9999;
    transition:200ms; transition-property:top,opacity,visibility;
    top:36px; opacity:0; visibility:hidden;
    pointer-events:none;
}
.search-box.show {
    top:56px; opacity:1; visibility:visible;
    pointer-events:auto;
}

.searchbox-content {
    min-width:480px; min-height:100px; position:relative;
    background-color:#efefef;
    box-shadow:0 0 0 1px rgba(0,0,0,.08), 0 4px 12px rgba(0,0,0,.35);
    border-radius:5px; overflow:hidden;
}

.searchbox-overlay {
    width:100%; height:100%; position:fixed; left:0; top:0;
    background-color:rgba(0,0,0,.1);
}

.search-box {

    .inputwpr {
        padding:6px 60px 6px 40px;
        position:relative;
        background-color:#fff;
        border-bottom:1px solid #e3e2e0;
    }
    .inputwpr .icon {
        width:20px; height:20px;
        position:absolute; left:20px; top:50%; margin-top:-10px;
        fill:#333;
    }

    .keyword {
        width:100%; font-size:16px;
        background-color:transparent; border:none;
        box-shadow:none;
    }

    .remark {
        padding:16px 20px 16px 50px; position:relative;
        font-size:14px; line-height:normal;
    }

    .remark .icon {
        width:15px; height:1px;
        position:absolute; left:23px; top:50%;
        background-color:#555;
    }
    .remark .icon:before,
    .remark .icon:after {
        content:""; display:block;
        width:15px; height:1px;
        position:absolute; left:0;
        background-color:#555;
    }
    .remark .icon:before {top:-5px;}
    .remark .icon:after {bottom:-5px;}

    .submitBtn {
        width:60px; height:100%;
        position: absolute; right:0; top:0;
        display:flex; justify-content:center; align-items:center;
        cursor:pointer;
    }
    .submitBtn svg {
        width:16px; height:16px;
        fill:#333;
    }
    .submitBtn:hover {background-color:#08728c;}
    .submitBtn:hover svg {fill:#fff;}

    .message {
        padding:8px 0 10px 0; margin:0 20px;
        font-size:14px;
        border-top:1px solid #dcdcdc;
    }
    .message.hide {display:none;}

}




/* ---------- Table ---------- */
.table-wrapper {

    th, td {text-align:center;}

    .fail, .alert {color:@btn-alert;}

    .withdraw {color:#a8a8a8;}

    .warning {color:#f3ad31;}

    // 比較小的文字
    .user-name {
        display:inline-block; margin-top:4px;
        font-size:12px; color:#888;
    }

    ._control {
        width:32px; padding:0 12px;
    }
    .btn-more {
        margin-left:0;
        background-color:transparent;
    }
    .btn-more:hover {background-color:#e8e7e3;}

    .control-menu {
        display:none; align-items:center;
        height:38px; padding:0 5px; z-index:10001;
        position:absolute; right:40px; top:50%; margin-top:-17px;
        background-color:#3b4149; border-radius:3px;
        // display:flex;
    }
    .control-menu:after {
        content:""; display:block; width:0; height:0;
        position:absolute; right:-8px; top:50%; margin-top:-8px;
        border-style:solid; border-width:8px 0 8px 9px;
        border-color:transparent transparent transparent #3b4149;
    }
    .control-menu:before {
        content:""; display:block;
        position:absolute; right:-40px; top:0;
        width:40px; height:100%;
    }

    .control-menu .btn {
        display:flex; align-items:center; justify-content:center;
        min-width:52px; height:28px; padding:0 4px;
        color:#fff; font-size:14px; white-space:nowrap;
        border-radius:1px;
        background-color:transparent; border:none;
    }

    .control-menu .btn:hover {
        background-color:#fb7061;
    }

    .control-menu .btn svg {
        width:14px; height:14px;
        fill:#fff; margin-right:5px;
    }
    .control-menu .edit svg {
        width:12px; height:12px;
    }

    .btn-more:hover {

        .control-menu  {display:flex;}
    }

}

.extra-wrapper {
    position:relative;
    margin-top:-10px; margin-bottom:10px;
    font-size:15px; line-height:1.3;
}

.extra-wrapper {

    p+p {margin-top:5px;}

    .sum {
        display:flex; align-items:center;
        position:relative;
    }

    .sum svg {
        width:9px; height:9px;
        fill:#fb7061; margin-right:8px;
        position:relative; top:1px;
    }

    .sum svg.search {
        width:12px; height:12px; top:0; fill:#107189;
    }

    .sum strong {
        padding:0 6px;
        font-weight:normal;
        background-color:#ddd;
        border-radius:2px;
    }

    .sum .prefix {
        display:inline-block; margin-right:8px;
        padding:4px 12px;
        font-size:14px;
        background-color:#fee3c0;
        border-radius:16px;
    }
}

/* ---------- Pagination ---------- */
// .hide 用來隱藏 .pag-wrapper (隱藏頁籤選單)
#tablePagination {
    width:100%; height:0;
    position:fixed; bottom:50px;
    left:0; right:0;
    display:flex; justify-content:center;
}

#tablePagination {

    .pag-wrapper {
        padding:0 10px; height:42px;
        background-color:#fff; border-radius:30px;
        box-shadow:0 0 1px rgba(11, 6, 6, 0.1), 0 1px 6px rgba(0,0,0,.12);
    }

    .btn {
        width:28px; height:28px; margin:0 2px;
        display:flex; justify-content:center; align-items:center;
        font-size:13px;
    }

    .btn.current {
        background-color:#0281b3;
        border-radius:20px;
    }

    // 額外的按鈕，例如匯出資料
    .btn-addon {
        height:42px;
        background-color:#e9e9e9;
        box-shadow:0 0 1px rgba(11, 6, 6, 0.2), 0 1px 6px rgba(0,0,0,.12);
        border-radius:22px;
    }
    .btn-addon:hover {
        color:#fff;
        background-color:#0088a6;

        svg {fill:#fff;}
    }
    .btn-addon:before {display:none;}

    .btn-addon.hidden {display:none;}

    .pag-wrapper+.btn-addon {margin-left:16px;}
}

// 隱藏頁籤選單，但不隱藏額外的按鈕
#tablePagination.hide {

    .pag-wrapper {display:none;}

    .pag-wrapper+.btn-addon {margin-left:0;}

}

