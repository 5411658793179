.main-footer {
    height:42px; display:flex; align-items:center; justify-content:space-between;
    color:#444; font-size:13px; line-height:1;
}

.main-footer {

    .group {
        display:flex; align-items:center;
    }

    .email {
        display:flex; align-items:center;
        margin-left:12px; height:30px; padding:0 12px;
        color:#444;
        border-radius:20px;
    }
    .email:hover {background-color:rgba(0,0,0,.1);}
    .email svg {
        width:17px; height:17px;
        position:relative; top:1px;
        fill:#333; margin-right:5px;
    }  

    .links {overflow:hidden;}

    .links {

        li {float:left;}    

        a {
            display:flex; align-items:center; justify-content:center;
            height:30px; padding:0 12px; color:#444; cursor:pointer;
            border-radius:20px;
        }
        a:hover {background-color:rgba(0,0,0,.1);}

    }

}

/* ----- RWD ----- */
@media only screen and (max-width:1200px) {

    .main-footer {
        height:auto; padding:10px;
        flex-direction:column; justify-content:center;
        line-height:1.4;
    }

    .main-footer {

        .group {
            flex-wrap:wrap; justify-content:center;
            text-align:center;
        }   

        .group p {text-align:center; margin:3px 6px;}
        .email {margin:3px 6px;}

        .links {
            display:flex; justify-content:center; align-items:center;
            flex-wrap:wrap;
        }

        .links {

            li {float:none;}
            li+li {margin-left:0;}
            a {padding:0 10px;}

        }    

    }

}



