@import url('../../css/variable.less');

.user-control {
    display:none; align-items:center; justify-content:center;
    position:relative; height:100%;
    // margin-left:10px;
}

.user-control.show {display:flex;}


.user-control {

    // 頭像
    .avatar {
        border-radius:50%;
        overflow:hidden; flex-shrink:0;
        background-size:cover; background-repeat:no-repeat;
        // background-color:#1a5570;
        cursor:pointer;
    }

    .avatar.empty {
        display:flex; justify-content:center; align-items:center;
        font-size:17px; color:#fff; line-height:1;
        // background-color:#00799e;
        background-color:@btn-teal2;
    }

    // header 的使用者選單按鈕
    #menuTrigger {
        width:38px; height:38px; position:relative;
        background-color:#eee;

        .icon {
            position:absolute; left:50%; top:50%;
            width:16px; height:2px; margin:-1px 0 0 -8px;
            background-color:#888;
            pointer-events:none;
        }
        .icon:before,
        .icon:after {
            content:""; display:block; width:16px; height:2px;
            position:absolute; left:0;
            background-color:#888;
        }
        .icon:before {top:-5px;}
        .icon:after {bottom:-5px;}

    }
    #menuTrigger:hover {
        background-color:#e1e1e1;

        .icon,
        .icon:before,
        .icon:after {background-color:#777;}
    }

    // #menuTrigger.avatar:hover {
    //     filter:brightness(1.1);
    // }

    // 使用者選單中的大頭相
    #userMenu .avatar {
        width:80px; height:80px; margin:0 auto 8px auto;
        cursor:default;
    }
    #userMenu .avatar.empty {font-size:38px;}

    // 尚未登入時的空白頭像
    .login .avatar {
        flex-shrink:0;
        display:flex; justify-content:center; align-items:center;
        background-color:#1f6d80;
    }
    .login:hover .avatar {background-color:#1294b1;}
    // 空白頭像中的 icon
    .avatar svg {
        width:18px; height:18px; fill:#2f333d;
        position:relative; overflow:hidden;
        top:-1px;
    }

    // 使用者選單
    #userMenu {
        width:280px; min-height:150px;
        position:absolute; right:0;
        text-align:center; line-height:1.3;
        // background-color:rgba(255,255,255,.96); border-radius:4px;
        box-shadow:0 2px 8px rgba(0,0,0,.15);
        pointer-events:none; visibility:hidden; top:36px; opacity:0;
        transition:400ms; transition-property:visibility,top,opacity;
    }
    #userMenu.open {
        pointer-events:auto; visibility:visible;
        top:46px; opacity:1;
    }

    .close-overlay {
        width:100%; height:100vh;
        position:fixed; left:0; top:0;
        background-color:rgba(0,0,0,.05);
    }

    .menuwpr {
        position:relative;
        background-color:#fff; border-radius:4px;
    }

    .group {
        position:relative; padding:14px;
    }
    .group+.group {
        border-top:1px solid #ddd;
    }

    .group.basic-info {padding-top:20px; padding-bottom:18px;}
    .group.btnwpr {padding:14px 14px 12px 14px;}

    .name {
        font-size:17px;
    }
    .email {
        font-size:13px; color:#999;
    }

    // 顯示目前權限、切換權限
    .roleTrigger {
        position:relative; padding:0 26px 0 14px;
        text-align:left; line-height:1; font-size:14px;
        cursor:pointer;
    }
    .roleTrigger:hover,
    .roleTrigger.open {
        color:#fff;
        // background-color:#137685;
        background-color:@btn-teal2;
    }

    .roleTrigger {

        .role-text {
            height:42px;
            display:flex; align-items:center;
            white-space:nowrap; overflow:hidden;
        }

        svg {
            width:16px; height:16px;
            position:absolute; right:8px; top:50%; margin-top:-7px;
            fill:#555;
        }
        .hint {
            height:42px;
            display:none; pointer-events:none;
            align-items:center; font-size:15px;
        }
        .role-text,.down {pointer-events:none;}

    }

    .roleTrigger:hover,
    .roleTrigger.open {
        svg {fill:#fff;}
        .role-text {display:none;}
        .hint {display:flex;}
    }

    .roleTrigger.open svg {transform:rotate(180deg);}

    // 選擇權限選單
    .switch-role-menu {
        padding:0; height:0;
        border-top:none !important;
        color:#333; text-align:left; font-size:14px;
        // background-color:#0f6978;
        background-color:#eee;
        overflow:hidden;
        // transition:300ms; transition-property:height;
    }
    .expand.switch-role-menu {
        // 選單開啟的時候必須 overflow visible，才能顯示子選單
        height:auto; overflow:visible;
        border-top:1px solid #0d606d !important;
    }

    .role-selector,
    .role-submenu-title {
        opacity:.9;
        cursor:pointer; position:relative;
        height:50px; padding:0 15px 0 21px;
        display:flex; align-items:center;
        border-top:1px solid rgba(0,0,0,.08);
    }

    .role-submenu-title {
        justify-content:space-between;
        font-size:14px;
    }

    .role-selector:hover, .role-submenu-title:hover {
        opacity:1;
        background-color:rgba(0,0,0,.08);
    }

    .role-selector.current {
        opacity:1; pointer-events:none;
        color:#fff;
        background-color:#1c4961;
    }

    .role-selector svg {
        width:18px; height:18px;
        position:absolute; left:3px; top:50%; margin-top:-8px;
        fill:#ff645d; display:none;
    }
    .role-selector.current svg {display:block;}

    .role-title {

        max-width:100%; pointer-events:none;

        span {
            display:block; overflow:hidden;
            white-space:nowrap;
        }

        .site-name {
            margin-top:2px; opacity:.8;
            font-size:13px;
        }

    }

    // 多個同樣權限時的子選單
    .role-submenu {position:relative;}

    .role-submenu {

        .length {
            display:flex; align-items:center; justify-content:center;
            min-width:22px; height:22px; margin-left:5px;
            padding:0 5px; box-sizing:border-box;
            background-color:rgba(0,0,0,.08); border-radius:3px;
        }

        .role-submenu-list {
            width:100%; z-index:500;
            position:absolute; left:-210px; top:-14px;
            background-color:#fafafa; border-radius:5px;
            box-shadow:0 1px 6px rgba(0,0,0,.3);
            overflow:hidden;
            display:none;
            overflow-y:auto;
        }

        .role-selector.current {background-color:#1c4961;}
    }

    .role-submenu:hover {

        .role-submenu-title {
            opacity:1;
            background-color:rgba(0,0,0,.08);
        }

        .role-submenu-list {
            display:block;
        }

    }

    .role-submenu.active {

        .role-submenu-title {
            opacity:1;
            color:#fff;
            // background-color:#106a79;
            background-color:#1c4961;
        }

        .role-submenu-title svg {fill:#ff645d;}
    }

    // 只有單一個權限的時候
    #userMenu.single {

        .roleTrigger {cursor:default;}

        .roleTrigger {
            svg {display:none;}
        }

        .roleTrigger:hover {
            color:#333;
            background-color:transparent;
        }

        .roleTrigger:hover {
            .role-text {display:flex;}
            .hint {display:none;}
        }

    }

    // 登出，更改密碼等
    .btn-user {
        padding:8px 10px;
        font-size:14px;
        background-color:#eee; border-radius:4px;
        border:1px solid #ddd;
        cursor:pointer;
    }
    .btn-user:hover {
        color:#fff;
        background-color:@btn-teal2;
        border:1px solid #157483;
    }
    .btn-user+.btn-user {
        margin-top:10px;
    }

    // 設定管理權限
    .btn-userMatch {
        display:flex; align-items:center; justify-content:center;
        position:relative; height:42px; padding:0 10px;
        color:#333; font-size:14px;
        // background-color:#fafafa;
        border-top:1px solid #ddd; border-bottom:1px solid #ddd;
        // box-shadow:inset 0 0 14px rgba(0,0,0,.05);
    }
    .btn-userMatch svg {
        width:12px; height:12px; margin-right:8px;
        // position:absolute; left:14px; top:50%; margin-top:-6px;
        fill:#333;
    }

    .btn-userMatch:hover {
        background-color:@btn-teal2;
        color:#fff;
        border-top:1px solid #0d5e6c; border-bottom:1px solid #0d5e6c;
        box-shadow:none;

        svg {fill:#fff;}
    }

    // 切換語言
    .lang-menu {
        display:flex; align-items:center; justify-content:space-evenly;
        height:42px; padding:0 10px;
        background-color:#f6f6f6;
        box-shadow:inset 0 0 15px rgba(0,0,0,.04);
        border-top:1px solid #ddd;
        border-bottom:1px solid #ddd;

        .btn {
            flex-basis:28%; height:26px;
            display:flex; justify-content:center; align-items:center;
            font-size:13px; white-space:nowrap;
            border-radius:20px;
            cursor:pointer;
        }

        .btn:hover {background-color:#eee;}

        .btn.current {
            color:#fff;
            background-color:#1975ac; pointer-events:none;
        }

        .btn.en {font-size:12px;}

        hr {
            width:22px; border-top:none; border-left:none; border-right:none;
            border-bottom:2px dotted #ccc;
        }

        .custom-btn.current {
            pointer-events:auto;
            background-color:#22a6a5;
        }
    }



}

