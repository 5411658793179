@import '../css/variable.less';

input.uppercase {
    text-transform:uppercase;
}

/* --- input radio and checkbox --- */
.check-wrapper {
    display:inline-flex; align-items:center; position:relative;
    -webkit-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    user-select:none; cursor:pointer;
    margin-right:15px;
    white-space:nowrap;
}
// .check-wrapper+.check-wrapper {margin-left:15px;}

.check-wrapper input {
    position:absolute; opacity:0;
    cursor:pointer; height:0; width:0; padding:0;
}

// for input radio
.radio {

    .checkmark {
        width:20px; height:20px; order:-1;
        position:relative; margin-right:6px;
        background-color:#fff; border-radius:50%;
        box-shadow:inset 0 0 0 1px rgba(0,0,0,.2);
        flex-shrink:0;
    }
    .checkmark:after {
        content:""; position:absolute; top:50%; left:50%;
        width:8px; height:8px; margin:-4px 0 0 -4px;
        background-color:#fff; border-radius:50%;
        display:none;
    }
}

// for input checkbox
.checkbox {

    .checkmark {
        width:20px; height:20px; order:-1;
        position:relative; margin-right:6px; top:1px;
        background-color:#fff; border-radius:2px;
        box-shadow:inset 0 0 0 1px rgba(0,0,0,.2);
        flex-shrink:0;
    }

    .checkmark:after {
        content:""; position:absolute; top:3px; left:7px;
        width:5px; height:9px;
        border-style:solid; border-width:0 3px 3px 0; border-color:#fff;
        -webkit-transform:rotate(45deg);
        -ms-transform:rotate(45deg);
        transform:rotate(45deg);
        display:none;
    }
}

// 黑色預設樣式
.check-wrapper:hover input ~ .checkmark {
    box-shadow:inset 0 0 0 1px rgba(0,0,0,.5);
}
.check-wrapper input:checked ~ .checkmark {
    background-color:#333;
    box-shadow:none;
}
.check-wrapper input:checked ~ .checkmark:after {
    display:block;
}

// 白色樣式
.check-wrapper input:checked ~ .checkmark.white {
    background-color:#fff;
    box-shadow:inset 0 0 0 1px rgba(0,0,0,.5);
}
.check-wrapper input:checked ~ .checkmark.white:after {
    border-color:#333;
}

// disabled
.disabled .check-wrapper {
    opacity:.6; color:#aaa; pointer-events:none;
}
.disabled p {color:#aaa;}

// 刪除的選項用紅色標示
.check-wrapper.delete input:checked ~ .checkmark {
    background-color:@btn-alert;
}

.check-wrapper .hint {
    position:absolute; left:-20px; top:25px; display:none;
    padding:4px 8px; z-index:9999; width:190px;
    white-space:normal; line-height:1.4;
    background-color:#fee4b7; box-shadow:0 1px 4px rgba(0,0,0,.2);
    border-radius:2px; pointer-events:none;
}
.check-wrapper:hover .hint {display:block;}


// sorting table 中的樣式
.sorting-table ._selector {

    width:30px;

    .check-wrapper {margin-right:0;}

    .checkmark {margin-right:0;}

}