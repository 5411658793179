// @import url('../../css/variable.less');

#navRoleStatus {display:none;}

.nav-inner {padding:0;}

.nav-entry {
    padding:0; height:66px; position:relative;
    display:flex; align-items:center;
    flex-direction:column; justify-content:center;
    color:#333; font-size:13px; text-align:center; 
    white-space:nowrap;
    border-bottom:1px solid #c7c4bc;
    box-sizing:border-box;
}
.nav-entry:last-child {border-bottom:none;}
.nav-entry:hover {background-color:#edece9;}

.nav-entry+.nav-entry {border-top:1px solid rgba(255,255,255,.4);}
.nav-entry.current+.nav-entry {border-top:none;}

.nav-entry.current {
    color:#000;
    background:linear-gradient(
        to bottom, 
        rgba(255,255,255,.5) 0%,
        rgba(255,255,255,.8) 100%
    );
} 

.nav-icon {
    position:relative; margin-bottom:2px;
    width:18px; height:18px;
    fill:#444;
}

.nav-entry.current .nav-icon {fill:#333;}

.nav-entry.current:before {
    content:""; display:block; width:100%; height:3px;
    position:absolute; left:0; bottom:-1px;
    background-color:#cfccc5;
}

// nav icon 特定項目微調不同尺寸
.nav-entry.panel-edges .nav-icon,
.nav-entry.panel-inspection .nav-icon {
    width:18px; height:18px;
}

.nav-entry.panel-inspection .nav-icon {top:1px;}

.nav-entry.panel-edges .nav-icon,
.nav-entry.panel-inspection .nav-icon {
    width:20px; height:20px;
}

.nav-entry.panel-inspection .nav-icon {top:0;}

// // 繁體中文
// body.tc {

//     .nav-entry {white-space:nowrap;}
    
// }

// 日文
body.jp {

    .sidebar:hover {z-index:11;}

    .nav-entry {

        .text {
            display:block; width:100%; padding:0 6px;
            position:relative; align-self:flex-start;
            box-sizing:border-box;
            border-radius:20px;
            overflow:hidden;
        }

    }
    .nav-entry:hover {

        .text {
            width:auto; min-width:100%;
            background-color:rgba(255,255,255,.95);
            overflow:visible;
            box-shadow:0 1px 4px rgba(0,0,0,.1);
        }

    }
    
}