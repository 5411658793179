@import './variable.less';

@import './input.less';
@import './switch.less';

@import './table.less';
@import './account.less'; // login, signup etc.
@import './landing.less'; // landing page or 未登入可看見的獨立頁面

/* --- reset --- */
body,dl,dt,dd,ul,ol,li,th,td,form,input,textarea,p {margin:0; padding:0;}
img {display:block; border:0;}
li {list-style:none}
sup {vertical-align:text-top;}
sub {vertical-align:text-bottom;}
article,aside,canvas,figure,figure img,figcaption,footer,header,hgroup,menu,nav,section,audio,video {display:block; margin:0; padding:0;}

/* --- global style --- */
html {
    height:100%;
    // background-color:#2a333f; // 深藍
    background-color:#f6f5f1; // 內容區塊的淺色
}
body {
    text-align:left; font-size:16px; line-height:1.6; color:#333;
    font-family:"Noto Sans TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-weight:400;
    /* font-weight 可用的值：400, 500 */
}

body.sc {font-family:"Noto Sans SC","微软雅黑","Microsoft Yahei",sans-serif;}
body.jp {font-family:"Noto Sans JP",sans-serif;}
// body.en {font-family:"Calibri", "Verdana",  sans-serif;}
// body.en {font-family:"Consolas", sans-serif;}

h1,h2,h3,h4,h5,h6 {margin:0; font-weight:500;}
p {text-align:justify; text-justify:distribute;}
a {outline:none; text-decoration:none;}
.clear {clear:both;}

svg {pointer-events:none; flex-shrink:0;}


/* --- for sitemap --- */
.sitemap {
    width:0; height:0; overflow:hidden;
    font-size:0; line-height:0;
    pointer-events:none;
}


/* --- layout --- */
body {
    display:flex; flex-direction:column;
    width:100%; height:100%;
}

.error {
    color:@btn-alert; font-size:14px;
}
/*

/* --- header --- */
header.header, footer.footer {width:100%;}

header.header {
    position:fixed; left:0; top:0; z-index:9999;
    background-color:#fff;
    box-shadow:0 0 6px rgba(0,0,0,.2), inset 0 0 10px rgba(0,0,0,.05);
}

/* --- footer (未登入時才顯示) --- */
footer.footer {
    position:relative;
    background-color:#f7f6f3;
}

/* --- sidebar --- */
.sidebar {
    width:154px; position:fixed; left:0;
    height:calc(100% - 46px); top:46px; // 扣掉 header 高度
    display:flex; flex-direction:column;
    background-color:#e6e4e0;
}
.sidebar:before {
    content:""; display:none; width:1px; height:100%;
    position:absolute; right:0; top:0;
    background-color:#dcd9d5;
}

.sidebar {

    .sidebar-nav {flex:1;}

    .sidebar-footer {
        position:relative;
        color:#666; font-size:12px; line-height:1; text-align:center;

        a {
            display:block; padding:8px 0;
            position:relative;
            color:#888;
        }
        a:hover {
            color:#333;
            background-color:rgba(0,0,0,.05);
        }
        a+a {border-top:1px solid #c6c4bc;}
        // a+a:before {
        //     content:""; display:block; width:100%; height:0px;
        //     position:absolute; top:0; z-index:10;
        //     border-top:1px solid #c6c4bc;
        //     border-bottom:1px solid #f0efec;
        // }
    }
    // p {text-align:center;}

    // .privacy {margin-top:6px;}

    // .privacy a {
    //     display:block;
    //     color:#666;
    // }
    // .privacy a:hover {color:#111;}
    // .privacy a+a:before {
    //     content:"/"; margin:0 5px;
    //     color:#666;
    // }
}

/* --- container --- */
.centerwpr,.main-container {
    margin:0 auto; position:relative;
}

.centerwpr {padding:0 20px; flex:1;}

main {
    flex:1; position:relative;
    padding-top:46px; padding-left:154px;
}

article.main {
    min-height:100%; position:relative;
    display:flex; flex-direction:column;
    background-color:#f6f5f1;
    box-shadow:0 0 6px rgba(0,0,0,.06);
}

.article-content {
    position:relative; flex:1;
    padding-top:67px; // 42px (page-header 的高度) + 25px
    padding-left:25px; padding-right:25px; padding-bottom:30px;
}
.unavailable.article-content {pointer-events:none; opacity:.3;}


/* --- 關閉主選單時 --- */
body.side-collapse {
    .sidebar {width:74px; box-shadow:inset 0 0 10px rgba(0,0,0,.1);}
    .sidebar:before {display:block;}
    main {padding-left:74px;}
    .page-header {width:calc(100% - 74px);}
}

/* ----- 小畫面一律顯示選單收合樣式 ----- */
@media screen and (max-width:1200px){

    .sidebar {width:74px; box-shadow:inset 0 0 10px rgba(0,0,0,.1);}
    .sidebar:before {display:block;}
    main {padding-left:74px;}

}

// fixed page header
.page-header {
    width:calc(100% - 154px); // 扣掉 sidebar 寬度
    height:42px; position:fixed; padding-top:3px;
    display:flex; align-items:center;
    line-height:1; font-size:15px;
    background-color:#eaeae6;
    box-shadow:0 0 4px rgba(0,0,0,.3);
    // z-index:9998;
    z-index:10;
}

.page-header {

    .breadcrumb {
        display:flex; align-items:center; flex-shrink:0;
        height:30px; padding:0 10px;
        color:#666; font-size:13px;
        border-right:1px solid #e6e5e1;

        a {color:#666; cursor:pointer;}
        a:hover {color:#333;}

        .home {
            display:flex; justify-content:center; align-items:center;
            width:30px; height:20px; margin-right:-3px;
            border-radius:4px;

            .icon {position:relative;}
            .icon, .icon:before, .icon:after {
                width:4px; height:4px;
                border-radius:50%;
                background-color:#999;
            }
            .icon:before, .icon:after {
                content:""; display:block;
                position:absolute; top:0;
            }
            .icon:before {left:-6px;}
            .icon:after {right:-6px;}
        }
        .home:hover {
            background-color:rgba(0,0,0,.04);

            .icon, .icon:before, .icon:after {
                background-color:#333;
            }
        }

        .spacer {
            width:13px; height:13px; margin:0 2px;
            fill:#999;
        }
    }

    // 目前所在頁面
    .current-page {
        font-size:15px; color:#222;
    }

    .count {
        margin-left:12px; font-size:14px; color:#aaa;
        letter-spacing:0;
    }

    .page-control {
        flex:1; position:relative;
        display:flex; align-items:center; justify-content:center;
        padding:0 15px;
    }

}

// ----- btn-addon ----- // 基本的控制按鈕樣式
.btn-addon {
    display:flex; align-items:center; justify-content:center;
    height:32px; padding:0 16px; margin-left:19px; position:relative;
    color:#333; font-size:15px; white-space:nowrap;
    border-radius:20px;
    cursor:pointer;
}
.btn-addon:hover {
    background-color:rgba(0,0,0,.06);
}
.btn-addon:before {
    content:""; display:block; width:1px; height:100%;
    position:absolute; left:-10px; top:0;
    background-color:#d3d3d3;
}
.btn-addon svg {
    width:16px; height:16px;
    fill:#333; margin-right:6px;
}

.btn-addon-hint {
    position:absolute; left:6px; bottom:-22px;
    padding:0 6px; height:22px;
    display:none; justify-content:center; align-items:center;
    font-size:13px; color:#fff;
    background-color:#f0504a; border-radius:3px;
    pointer-events:none;
}
.btn-addon-hint:before {
    content:""; display:block; width:0; height:0;
    position:absolute; left:12px; top:-5px;
    border-style:solid; border-width:0 5px 5px 5px;
    border-color:transparent transparent #f0504a transparent;
}

// disabled 樣式
.page-control.unavailable .btn-addon,
.btn-addon.disabled {
    cursor:default;
    color:#bbb;

    svg {fill:#bbb;}
}
.page-control.unavailable .btn-addon:hover,
.btn-addon.disabled:hover {

    background-color:transparent;

    .btn-addon-hint {display:flex;}

}


/* ----- (basic) 沒有左側選單的基本版型，用在 terms, rules 等純文字頁面 ----- */
body.basic {
    height:auto; min-height:100vh;
    background-color:#f2f0eb;
}
body.basic {

    main {
        padding-left:0;
        transition-duration:0; transition-property:none;
        max-width:800px; margin:0 auto;
    }

    article {padding:50px 25px; font-size:15px;}

    article {

        section {padding:20px 0;}

        section+section {
            margin-top:10px;
        }

        h1 {font-size:35px; margin-bottom:20px;}
        h2 {font-size:25px; margin-bottom:10px;}
        h3 {
            margin-bottom:10px; padding-bottom:6px;
            font-size:22px;
            border-bottom:1px solid #e3e3e3;
        }

        strong {
            font-weight:normal; padding:0 6px;
            background-color:#ffeec0;
        }

        .update {font-size:14px; margin-bottom:10px;}

        p+p {margin-top:10px;}

        ol, ul {margin:10px 0;}

        li {margin-left:17px; padding-left:6px;}
        ol li {list-style:decimal;}
        ul li {list-style-type:disc;}

        ol ol {margin-top:10px; margin-left:20px;}
        ol ol li {list-style:upper-alpha;}

        a {
            color:#005cb7;
            border-bottom:2px dotted #005cb7;
        }
        a:hover {
            color:#333;
            border-bottom:2px dotted #333;
        }
    }
}


/* --- form, input, select, etc... --- */
form {margin:0 auto;}

.inputwpr {position:relative;}

input,select,textarea {
    color:#444; font-size:14px; line-height:normal;
    font-family:"Noto Sans TC", "微軟正黑體", "Microsoft JhengHei", sans-serif;
    font-weight:400;
}

input, select {
    padding:5px 10px;
    border:none;
    -webkit-appearance:none; outline:none;
}

input {
    vertical-align:middle; box-sizing:border-box;
    background-color:rgba(0,0,0,.05);
    border-radius:4px;
    box-shadow:inset 0 0 0 1px rgba(0,0,0,.1);
}
input[type="text"],
input[type="email"] {
    height:32px;
}

select {
    padding-right:25px;
    background-color:transparent;
}

.selectwpr {
    position:relative; min-width:110px;
    background-color:rgba(0,0,0,.05);
    border-radius:4px;
    box-shadow:inset 0 0 0 1px rgba(0,0,0,.1);
}
.selectwpr:after {
    content:""; display:block; width:0; height:0;
    position:absolute; right:10px; top:50%; margin-top:-1px;
    border-style:solid; border-width:5px 5px 0 5px;
    border-color:#666 transparent transparent transparent;
    pointer-events:none;
}

.selectwpr select {width:100%;}

.selectwpr.disabled {
    background-color:transparent;
}
.selectwpr.disabled:after {opacity:.4;}

.selectwpr.disabled select {pointer-events:none;}


/* --- input (date) --- */
.date-picker {
    height:34px;
    display:inline-flex; align-items:center;
    background-color:rgba(0,0,0,.05); border-radius:4px;
    box-shadow:inset 0 0 0 1px rgba(0, 0, 0, .08);
}

/* Removes the clear button from date inputs (清除按鈕) */
input[type="date"]::clear-button {
    display:none;
}
input[type="date"]::-webkit-clear-button {
    display:none;
}

/* Removes the spin button (上下箭頭) */
input[type="date"]::-webkit-inner-spin-button {
    display:none;
}

/* 向下的小三角形，可以改用 icon 代替 */
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity:0;
    width:100%; height:28px;
    position:absolute; left:0; top:0;
}

/* A few custom styles for date inputs */
input[type="date"] {
    position:relative; cursor:pointer;
    padding:8px 12px; box-sizing:border-box;
    display:inline-block !important; visibility:visible !important;
    font-size:15px; line-height:1; font-family:arial, sans-serif;
    background-color:transparent; border-radius:0;
    box-shadow:none;
}

input:disabled {
    background-color:rgba(0,0,0,.04);
    box-shadow:none;
    pointer-events:none;
}


/* --- common overlay --- */
.overlay {
    width:100%; height:100%;
    position:fixed; left:0; top:0;
    cursor:default;
}


/* --- btn more --- */
.btn-more {
    width:32px; height:32px; position:relative; margin-left:10px;
    border-radius:50%; background-color:#e8e7e3;
    cursor:pointer; padding:0;
}
.btn-more:hover {background-color:#e8e7e3;}

.btn-more {

    .icon {
        width:4px; height:4px; position:absolute; left:50%; top:50%;
        margin:-2px 0 0 -2px;
        border-radius:50%; background-color:#aaa;
        pointer-events:none;
    }
    .icon:before,
    .icon:after {
        content:""; display:block; width:4px; height:4px;
        position:absolute; top:0;
        border-radius:50%; background-color:#aaa;
    }
    .icon:before {left:-6px;}
    .icon:after {right:-6px;}

    .overlay-more {
        width:100%; height:100%; z-index:10000;
        position:fixed; left:0; top:0;
        display:none; cursor:default;
    }

}
.btn-more:hover {
    .icon,
    .icon:before,
    .icon:after {
        background-color:#555;
    }
}

.btn-more.expand {

    .overlay-more {display:block;}

}



/* --- page-control 中的操作按鈕 --- */
// TODO: 用 btn-addon 取代 btn-control
.btn-control {
    position:relative; margin-left:25px; height:34px; padding:0 16px;
    display:flex; align-items:center; justify-content:center;
    font-size:15px; white-space:nowrap; color:#fff;
    background-color:#008398; border-radius:20px;
    cursor:pointer;
}
.btn-control+.btn-control {
    margin-left:25px;
}
.btn-control+.btn-control:after {
    content:""; display:block; width:1px; height:100%;
    position:absolute; left:-13px; top:0;
    background-color:#e6e5e1;
}

.btn-control:hover {background-color:#11758d;}
.btn-control svg {
    width:16px; height:16px;
    fill:#fff; margin-right:8px;
}

// 不能按的情況
.unavailable .btn-control {
    display:none; pointer-events:none;
}


/* --- loading --- */
body.loading #loading {display:flex;}

#loading {
    width:100%; height:100%; z-index:20000;
    position:fixed; left:0; top:0;
    display:none; justify-content:center; align-items:center;
}
.loading-box {
    padding:10px 20px; position:relative;
    display:flex; flex-direction:column; align-items:center;
    font-size:14px;
    background-color:rgba(255,255,255,.6);
    box-shadow:0 2px 20px rgba(0,0,0,.1);
    border-radius:6px;
}

// 自訂訊息
body.loading-custom {

    .loading-box:after {
        content:attr(data-msg); display:block;
        position:absolute; top:100px; min-width:180px;
        padding:6px 12px; white-space:nowrap;
        text-align:center; line-height:1.2;
        background-color:#fdd98d; border-radius:4px;
    }

}

/* --- feedback --- */
#feedbackMsg {
    position:fixed; z-index:99999; bottom:50px; right:20px;
    display:flex; flex-direction:column;
    justify-content:flex-end; align-items:flex-end;
    font-size:14px; line-height:1.4;
    // pointer-events:none;
}
#feedbackMsg {

    p {
        max-width:500px;
        padding:15px 20px; min-height:30px;
        display:flex; align-items:center;
        position:relative; top:10px;
        border-left:6px solid #0093ad;
        background-color:#fff;
        border-radius:0 3px 3px 0;
        box-shadow:0 2px 4px rgba(0,0,0,.1);
        transition-duration:300ms; transition-property:opacity,top;
        opacity:0;
    }

    p+p {margin-top:6px;}

    .icon {
        width:22px; height:22px;
        display:flex; justify-content:center; align-items:center;
        flex-shrink:0;
        margin-right:8px;
        background-color:#0093ad;
        border-radius:20px;
    }

    svg {
        width:12px; height:12px;
        fill:#fff;
    }

    .fail {

        border-left-color:#ea5047;

        .icon {
            background-color:#ea5047;
        }
        .icon svg {width:14px; height:14px;}
    }

    p.show {
        top:0; opacity:1;
    }

}

/* ---------- fastdial layout ---------- */
.fastdial {

    main {
        height:100vh; box-sizing:border-box;
        overflow:hidden;
    }

    article.main {
        height:100%; overflow:hidden;
    }

    .article-content {
        padding:42px 0 0 0;
        display:flex;
        height:100%; box-sizing:border-box; overflow:hidden;
    }

    #fdContainer {
        min-width:280px; flex-basis:20%;
        position:relative; margin-right:30px;
        display:flex; flex-direction:column;
    }

    .scrollwpr {
        flex:1;
        overflow-x:hidden; overflow-y:auto;
    }

    #listContainer {
        flex:1; position:relative; padding-right:35px;
        overflow-x:hidden; overflow-y:auto;
    }

}

// >> 站點的數據比較模式，會受到 fastdial 的影響，所以多加一個 class 做判斷 >> //
body.siteSorting {

    main {height:auto; box-sizing:border-box; overflow:visible;}

    article.main {height:auto; overflow:visible;}

    .article-content {
        // >> 同 common.less 中的初始設定 >> //
        position:relative; flex:1;
        padding-top:67px; // 42px (page-header 的高度) + 25px
        padding-left:40px; padding-right:40px; padding-bottom:30px;
        display:block; height:auto; box-sizing:content-box;
        overflow:visible;
    }

    #listContainer {
        position:static; padding-right:0;
        overflow-x:visible; overflow-y:visible;
    }

}

#fdContainer {

    .fd-menu {
        padding:0 10px;
        font-size:14px;
    }

    .fd-menu-solt {overflow:hidden; padding:10px 0;}
    .fd-menu-solt.hide {display:none;}

    .fd-menu-solt+.fd-menu-solt {
        margin-top:10px;
    }

    h4 {
        display:flex; align-items:center; justify-content:space-between;
        height:36px; margin-bottom:10px; padding:0 10px;
        font-size:19px; font-weight:normal; white-space:nowrap;
        background-color:#fff; border-bottom:2px solid #dadada;
        cursor:pointer; overflow:hidden;
    }

    .sites h4 {font-size:18px;}

    h4 .icon {
        width:16px; height:16px; order:2;
        display:flex; justify-content:center; align-items:center;
        background-color:#444; border-radius:3px;
        box-shadow:inset 0 0 2px #aaa;
        pointer-events:none;
    }
    h4:hover .icon {background-color:#333;}

    h4 .icon svg {width:10px; height:10px; fill:#fff}

    svg.plus {display:none;}

    .length {
        display:inline-block;
        margin-bottom:6px; margin-top:2px; padding:2px 12px;
        font-size:13px;
        background-color:rgba(0,0,0,.1);
        border-radius:16px;
    }

    li {cursor:pointer}
    // li+li {border-top:1px solid #ddd;}

    .fd-menu-item {
        white-space:nowrap; position:relative;
        display:flex; align-items:center;
        height:38px; padding:0 10px;
        color:#444; border-radius:2px;
        border-bottom:1px solid #ddd;
    }
    .fd-menu-item:before {
        content:""; display:block; width:6px; height:6px;
        margin-right:7px; flex-shrink:0;
        background-color:#444; border-radius:50%;
        pointer-events:none;
    }

    .fd-menu-item:hover {background-color:#008398; color:#fff;}
    .fd-menu-item:hover:before {background-color:#fff;}

    .fd-menu-item.disabled {opacity:.3;}

    .type4 .fd-menu-item {padding-left:21px;}
    .type4 .fd-menu-item:before {
        width:5px; height:5px;
        border-top:2px solid #333; border-right:2px solid #333;
        background-color:transparent; border-radius:0;
        transform:rotate(45deg);
    }

    .type4 .fd-menu-item:hover:before {
        border-top-color:#fff; border-right-color:#fff;
    }

    /* ----- 子選單收起 ----- */
    .fd-menu-solt.collapse {padding-bottom:0;}
    .fd-menu-solt.collapse {

        h4 .icon {background-color:#333;}
        svg.minus {display:none;}
        svg.plus {display:block;}

        ul {display:none;}
    }

    /* ----- 子項目 ----- */
    .fd-menu-item.hide {
        display:none;
    }

    /* ----- 搜尋輸入框 ----- */
    .fd-search {
        padding:10px 0 10px 10px; position:relative;
    }
    .fd-search {

        svg.search {
            width:16px; height:16px; fill:#333;
            position:absolute; left:20px; top:50%; margin-top:-8px;
            pointer-events:none;
        }

        #fdMenuKeyword {
            width:100%; padding-left:32px; height:36px;
            font-size:15px;
            border-radius:2px;
        }

        #fdSearchMsg {
            position:absolute; left:0; top:52px;
            padding-left:10px; box-sizing:border-box; width:100%;
            font-size:14px; color:#888; text-align:center;
        }

    }

}

/* ----- input filter reset btn ----- */
.resetInput {
    width:24px; height:24px;
    position:absolute; top:50%; margin-top:-12px; right:28px;
    display:flex; align-items:center; justify-content:center;
    background-color:#e3e3e3; border-radius:50%;
    cursor:pointer;
}
.resetInput svg {
    width:14px; height:14px; margin-right:0;
    fill:#333;
}
.resetInput.hide {display:none;}

.resetInput:hover {background-color:#333;}
.resetInput:hover svg {fill:#fff;}

.fd-search .resetInput {
    right:10px; background-color:#d3d3d3;
}
.fd-search .resetInput:hover {
    background-color:#333;
}

/* ----- 顯示系統公告 ----- */
#sysAnnowpr {
    height:100%; padding:6px 12px; box-sizing:border-box;
    display:flex; align-items:center; justify-content:center; flex:1;
    font-size:13px; line-height:1.2;
}
#sysAnnowpr {

    .icon {
        width:20px; height:20px; flex-shrink:0; margin-right:5px;
        display:flex; align-items:center; justify-content:center;
        background-color:#ffb649; border-radius:50%;
    }
    svg {
        width:12px; height:12px; fill:#fff;
    }

}

/* ----- page-header 目前作用中權限及所屬站點 (只在 nav 收起時顯示) ----- */
#headRoleStatus {
    position:relative; padding:0 10px; height:100%;
    line-height:1.2; font-size:14px; text-align:left;
    // border-left:1px solid #d3d3d3;
    // background-color:#e6e5e2;
}

.side-collapse #headRoleStatus {display:block;}

#headRoleStatus {

    .innerbox {
        height:100%; display:flex; align-items:center;
        overflow:hidden;
        // pointer-events:none;
    }

    .role {
        padding:6px 12px; margin-left:5px;
        color:#fff; white-space:nowrap; font-size:13px;
        background-color:@btn-black-teal;
        border-radius:20px;
        cursor:pointer;
    }

    .site {
        padding-left:10px;
        white-space:nowrap; text-align:center;
        border-left:1px solid #ddd;
    }

    .note {
        max-width:120px; margin-right:10px;
        height:34px; overflow:hidden;
        display:flex; align-items:center;
        text-align:right;
    }
}

/* ----- api 錯誤提示對話框 ----- */
#apiErrBox {
    z-index:99998;

    .dialog-overlay {display:none !important;}
}

/* ----- message box，只有關閉按鈕，單純顯示訊息 ----- */
#msgBox {

    z-index:99997;

    .dialog-container {max-width:360px;}

    p+p {margin-top:10px;}

    ul {margin-left:15px;}
    li {list-style:disc;}

    .status {
        padding:2px 6px; margin-right:6px;
        font-size:15px;
        background-color:#ffe2ac;
        border-radius:2px;
    }
    .status.fulfilled {background-color:#7eebd1;}
    .status.rejected {background-color:#ff9a9a;}

    hr {
        margin:15px 0; height:1px;
        background-color:#ccc;
        border-top:none; border-left:none; border-right:none;
        border-bottom:1px solid #fff;
    }
}

/* ---------- page control 中選擇模式的按鈕 ---------- */
.page-tabwpr {
    display:flex; margin:0 20px;
}

.page-tabwpr {

    .btn-tab {
        height:32px; padding:0 20px; min-width:80px;
        display:flex; align-items:center; justify-content:center;
        color:#555;
        // background-color:#fff;
        background-color:rgba(255,255,255,.7);
        // box-shadow:inset 0 0 0 1px rgba(0,0,0,.06);
        border:1px solid #d9d8d5;
        cursor:pointer;
    }
    .btn-tab+.btn-tab {border-left:none;}
    // .btn-tab:nth-child(1) {border-radius:3px 1px 1px 3px;}
    // .btn-tab:nth-child(2) {border-radius:1px 3px 3px 1px;}
    .btn-tab:first-child {border-radius:4px 0 0 4px;}
    .btn-tab:last-child {border-radius:0 4px 4px 0;}

    .btn-tab.selected {
        color:#fff;
        background-color:@btn-teal2; border-color:@btn-teal2;
        pointer-events:none;
    }

    .btn-tab:hover {
        // background-color:#e8e8e8; color:#333;
        // background-color:rgba(255,255,255,.8);
        background-color:#fff;
    }

    .icon-checked {
        pointer-events:none;
        width:6px; height:6px; margin-right:8px;
        position:relative; top:1px;
        border-radius:50%;
        display:block; border:3px solid #ccc;
    }

    // .btn-tab:hover .icon-checked {border-color:#ccc;}

    .btn-tab.selected .icon-checked {border-color:#fa6e6e;}

}


// ----- 取消載入資料 (使用在 dashboard loading 提示中) ----- //
#cancelFetchData {
    display:flex; align-items:center; justify-content:center;
    min-width:104px; box-sizing:border-box;
    padding:6px 16px; margin-top:15px;
    font-size:14px;
    border-radius:20px;
    background-color:#ffdfbe; cursor:pointer;
    box-shadow:0 1px 2px rgba(0,0,0,.05);

    svg {
        width:14px; height:14px; margin-right:4px;
        fill:#333;
    }
}
#cancelFetchData:hover {
    background-color:#b94e42; color:#fff;

    svg {fill:#fff;}
}


// RWD
@media screen and (max-width: 1300px) {

    // 最小寬度的相關設定
    body,
    header.header {min-width:1260px;}

    header.header,
    .page-header {position:absolute;}

    .page-header,
    body.side-collapse .page-header {width:100%;}
    // 最小寬度的相關設定

    body.login,
    body.signup,
    body.forgotpw,
    body.resetpw,
    body.user-match,
    body.basic,
    body.unauth {
        min-width:fit-content;

        header.header {min-width:fit-content;}

        .main-header .logo {margin-left:0;}
        // .trigger-container {width:0;}
    }

    // 綁定權限頁，隱藏 header 的一些按鈕
    body.user-match {
        .header {
            #extraBtnWpr {display:none;}
            // #btnAppDwn {display:none;}
        }
    }


    .page-header .breadcrumb {

        // 只顯示目前所在頁面
        a, .spacer {display:none;}
        .current-page {font-size:14px;}

    }

}

@media screen and (max-width: 580px) {

    body.login,
    body.signup,
    body.forgotpw,
    body.resetpw,
    body.user-match,
    body.basic,
    body.unauth {
        // #btnAppDwn {display:none;}
        #btnSA .icon {display:none;}
    }

}

@media screen and (max-height: 700px) {

    body {min-height:700px;}

    .sidebar {position:absolute;}

}
