@import '../../css/variable.less';

#licenseUsageReportBox {

    .dialog-container {
        // max-width:1700px; 
        max-width:1400px; 
        height:calc(100% - 20px); max-height:calc(100% - 20px);
        padding:0;
        // background-color:#dfdfdf;
    }

    .content.no-scroll {        
        overflow:hidden !important;
    }

    .innerwpr {
        padding:0; display:flex; flex-direction:column;  
    }

    .sumPanel {
        padding:10px 20px; position:sticky; top:0;      

        .info {
            display:flex; align-items:center; justify-content:space-between;
            margin-bottom:8px;

            .group {display:flex; align-items:center;}

            .tag {
                padding:6px; margin-right:6px; min-width:26px;
                font-size:14px; text-align:center; line-height:1;
                white-space:nowrap;
                background-color:#f6f6f6; border-radius:2px;
            }
            .tag.type {background-color:#ffdc98;}

            h4 {
                margin-left:6px; position:relative; top:-1px;
                font-size:19px; line-height:1; font-weight:normal;
            }
        }

        .instruction li {
            list-style:disc; margin-left:15px;
            font-size:14px;

            strong {background-color:#ffceaf;}
        }
    }

    .reportPanel {
        position:relative; padding:0 20px; overflow-y:auto;

        .fade {color:#bbb;}
    }

    /*
    table {
        font-size:15px; text-align:center;

        .company,
        .company_type,
        .enable_punch_in,
        .enable_hr_bp {font-size:14px;}

        .company {text-align:left;}
    
        td._noCharge {color:#bbb;}

        .hr {margin:0 5px;}

        small {font-size:12px;}

        .charge, .nocharge {
            display:inline-block; min-width:24px;
            padding:3px 5px;            
        }
        .nocharge {
            color:#aaa;
            background-color:#eee;
        }       

        strong {background-color:#b1fdcb;}
    }
    */
    .reportPanel table {
        text-align:center;  
        border-collapse:separate; 

        thead {
            // 多行組合的時候，原本設定在 th 上的 sticky 行為會不太正確
            // 改成設定在 thead 上
            position:sticky; top:0; z-index:100;    
        }
    
        th {
            position:relative;
            // position sticky 時 border 顯示會不太正確，這邊用 box-shadow 代替            
            box-shadow:0 0 0 1px #0a5c73;
            height:26px;
        }

        th.lv2, th.lv3 {font-size:13px;}

        tr.lv1 th.single {
            background-color:#188aa0;         
        }
        tr.lv2 th.single {background-color:#1b8095;}

        tr.lv1 th.multi {
            background-color:#188aa0;      
        }
        tr.lv2 th.multi {background-color:#1b8095;}

        .hidden {display:none;}

        // 計算經銷客戶的數量總計
        .client-sum td {
            height:28px;
            color:#eaedf0; font-size:14px;
            background-color:#2b4a68;
            
            strong {
                margin-left:0; margin-right:6px; padding:1px 5px;
                color:#111;
                background-color:#ffc263;
            }
        }

        // 經銷商列的顏色加深
        tr.t-2 {
            background-color:#d6d6d6;

            td {border-bottom-color:#bbb;}

            .fade {color:#a8a8a8;}
        }
       
    } 

    // 關閉按鈕列
    .btnwpr {padding:15px 20px; margin-top:0;} 

    .title {
        height:50px; margin:0; box-sizing:border-box;    
    }
    // .title svg {margin-right:0;}

    .control-menu {
        flex-grow:1;
        display:flex; align-items:center; justify-content:center;
    }

    .picker+.picker {margin-left:10px;}

    // 開始查詢按鈕
    #LURQuerySubmit {
        width:140px; height:34px; margin-left:20px;
        display:flex; align-items:center; justify-content:center;
        font-size:15px; color:#fff;
        background-color:@btn-black-teal; border-radius:20px;
        cursor:pointer;
    }
    #LURQuerySubmit:hover {
        background-color:@btn-black-teal-hover;
    }

    .empty-hint {     

        .content {
            display:inline-block; padding:25px; margin-bottom:0;
            text-align:center; font-size:15px;
            // background-color:#eee; border-radius:4px;
            // box-shadow:0 0 0 1px #e8e8e5;
        }

        h4 {font-size:19px; margin-bottom:5px;}
        p {text-align:center; display:block;}

    }    

}

// 還沒開始查詢或沒有報表內容時
#licenseUsageReportBox.no-data {

    #exportLURdata {
        pointer-events:none;
        color:#b3b3b3;
        background-color:#eee;
    }

}