@import '../../css/variable.less';

.month-picker{

    position:relative;
    color:#333;

    .picker-overlay {
        position:fixed; width:100%; height:100%; 
        top:0; left:0;
        background-color:@overlay-grey;
        display:none;
    }

    .picker-label {
        display:flex; align-items:center; 
        position:relative; cursor:pointer;
        padding:0 28px 0 10px; height:34px; min-width:130px;
        font-size:15px; line-height:1;
        background-color:@bg-picker-label; border-radius:3px;
        user-select:none;
    }
    .picker-label span {margin:0 3px;}
    
    .picker-label-txt {pointer-events:none;}
    
    .picker-label svg {
        width:14px; height:14px;
        position:absolute; right:7px; top:50%; margin-top:-7px;
        fill:#333; pointer-events:none;
    }

    .picker-content {
        position:relative; z-index:1001; 
        width:300px; margin-left:-150px; // TEST
        margin-top:3px; position:fixed; left:50%;
        background-color:#f6f5f1;
        box-shadow:0 0 6px rgba(0,0,0,.25);   
        border-radius:2px;
        display:none;
    }

    .yearwpr {
        display:flex; align-items:center; justify-content:space-evenly;
        padding:10px;
        background-color:#fff;
        border-bottom:2px solid #dadada;
    }

    .pick-year {
        font-size:19px; position:relative; top:-1px;
    }

    .picker-arrow {
        width:30px; height:30px;
        display:flex; align-items:center; justify-content:center;
        background-color:#e3e3e3; border-radius:50%;
        cursor:pointer;

        svg {width:20px; height:20px; fill:#333;}
    }

    .yearwpr.disabled {
        .picker-arrow {display:none;}
    }

    .monthwpr {
        display:flex; flex-wrap:wrap; padding:10px;
    }

    .month-brick {
        flex-basis:25%; flex-grow:1;
        display:flex; align-items:center; justify-content:center;
        flex-direction:column;
        height:64px; padding:5px; box-sizing:border-box;
        white-space:nowrap; font-size:15px;
        cursor:pointer; border-radius:2px;
    }
    .month-brick span {pointer-events:none; user-select:none;}
    .month-brick .alt {font-size:13px; margin-top:4px;}

    .month-brick:hover {
        background-color:#e3e3e3;
    }
    .month-brick.selected {
        background-color:#008398; color:#fff;
    }


}


.month-picker.expand {

    z-index:2000;

    .picker-label {background-color:@bg-picker-label-expand;}

    .picker-overlay, .picker-content {display:block;}

}