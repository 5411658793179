.search-cond+.device-sum-panel {margin-top:5px;}

.device-sum-panel {
    display:flex; align-items:center; flex-wrap:wrap;
    // padding:1px;
    // background-color:rgba(0,0,0,.15);

    .slot {
        display:flex; flex-basis:calc(49.5% - 4px); flex-grow:1;
        margin:2px;
        font-size:14px; line-height:1.2;
        // background-color:#e8e7e5;
        // border:1px solid #bbb;
        background-color:#eaeae6; border-radius:20px;
        box-sizing:border-box;
    }
    .slot.spacer {
        background-color:transparent;
        // border-color:transparent;
    }

    .slot.all_device,
    .slot.all {
        background-color:#fff;
        // border-color:#ccc;
    }

    .cell {
        display:flex; align-items:center; flex-basis:100px;
        padding:4px 6px; box-sizing:border-box;
        white-space:nowrap;
        flex-grow:1;
    }

    .empty {opacity:.3;}

    // .cell.name, .cell.group {flex-basis:140px; white-space:nowrap;}

    // .cell.name,
    // .cell.c-1,
    // .cell.billed {flex-grow:1;}

}

@media screen and (max-width: 1500px) {

    // 資訊統計頁，需要額外考慮數量統計區塊的寬度 180px
    body.panel-info {

        .device-sum-panel .slot {flex-basis:calc(100% - 4px);}

    }

}

@media screen and (max-width: 1350px) {

    .device-sum-panel .slot {flex-basis:calc(100% - 4px);}

}