@import '../../css/variable.less';

.inventory-alert-count {
    display:flex; align-items:center; justify-content:center;
    padding:0 5px; height:20px; min-width:10px; margin-left:5px;
    font-size:12px; color:#fff; line-height:1;
    background-color:#d96c8a;
    border-radius:3px; pointer-events:none;
}

#inventoryAlertTrigger {

    .inventory-alert-count {height:18px;}

}
#inventoryAlertTrigger.disabled {
    pointer-events:none;
    color:#d9d9d9;

    .inventory-alert-count {
        background-color:#e3e3e3;
    }
}


#inventoryAlert {

    .content .innerwpr {padding:0;}

    .minimum,
    .quantity,
    .difference,
    ._control {text-align:center;}

    .minimum,
    .quantity,
    .difference {font-size:14px;}

    td.quantity,
    td.difference {
        color:@btn-alert;
    }

    ._control .wrapper {
        display:flex; align-items:center; justify-content:center;

        .btn {
            margin:0; padding:6px 10px;
            background-color:#e8e7e3;
            border:none;
        }
        .btn:hover {
            color:#fff;
            background-color:#0088a6;
        }

        .btn+.btn {margin-left:10px;}

        .btn.active {
            color:#fff;
            background-color:#393d49;
            pointer-events:none;
        }
    }





}