// 預設
body.default {

    .main-header .logo {
        width:110px; height:34px; top:3px;
        background-image:url("/assets/images/logo/alcolog.svg");
    }
    .main-header .logo:after {
        content:attr(data-country); display:block;
        position:absolute; left:92px; top:0;
        font-size:12px; color:#806b62; font-weight:500;
        line-height:1;
    }

    .accountModal .header .logo,
    main .match-container .header .logo
    {
        margin-bottom:10px;
        background-image:url("/assets/images/logo/alcolog.svg");
    }

    .accountModal .header .logo:after,
    main .match-container .header .logo:after {
        content:attr(data-country); display:block;
        position:absolute; left:178px; top:0;
        font-size:16px; color:#806b62; font-weight:500;
        line-height:1;
    }

}

// dev
body.default.dev {

    .main-header .logo {
        width:125px;
    }
    .main-header .logo:before {
        content:"DEV"; display:block; padding:2px 6px;
        position:absolute; left:92px; bottom:5px;
        font-size:11px; color:#fff; font-weight:500;
        line-height:1;
        background-color:#333; border-radius:2px;
    }

}

// API 說明頁
body.document {
    header.header {display:none;}
    main {padding:30px;}
}


// 客製化 common
body.acerits,
body.tripodworks {

    // login 歡迎標題
    // .accountModal .header h2 {display:none;}

    // 繼續代表您同意服務條款 & 隱私權政策
    .rules-link {display:none;}

    // footer
    footer.footer,
    .sidebar .sidebar-footer {display:none;}

    // 軟體下載按鈕
    // #btnAppDwn {display:none;}

    // 隱藏第三方登入與註冊
    .social-block,
    .sign-btn.facebook,
    .sign-btn.google,
    .auth-note {display:none;}

    .login .footer {justify-content:center;}
}

// 宏碁智通
body.acerits {

    .main-header .logo {
        width:151px; height:36px;
        background-image:url("/assets/images/logo/acerits/logo-header.png");
    }

    // account, match
    .accountModal .header .logo,
    main .match-container .header .logo
    {
        width:218px; height:73px;
        background-image:url("/assets/images/logo/acerits/logo-main.png");
    }

}

// tripodworks
body.tripodworks {

    .main-header .logo {
        width:120px; height:100%; margin-left:-20px;
        background-color:#e50010;
        background-image:url("/assets/images/logo/tripodworks/logo-header.svg");
        background-position:center center;
        background-size:auto 100%;
    }

    // account, match
    .accountModal .header,
    main .match-container .header {
        padding-top:30px;

        .logo {
            width:137px; height:99px; margin-bottom:20px;
            background-image:url("/assets/images/logo/tripodworks/logo-main.png");
        }

    }

}

body.tripodworks.unauth,
body.tripodworks.user-match {
    .main-header.centerwpr {padding-left:0;}
}

// RWD
@media screen and (max-width:1200px){

    body.tripodworks {
        .main-header.centerwpr {padding-left:0;}
    }

}