@import '../css/variable.less';

/* --- table --- */
.table-wrapper {position:relative;}
table {
    min-width:100%;
    font-size:13px; line-height:1.2;
    border-collapse:collapse; border-spacing:0;
    background-color:#fff;
}

thead {
    th {
        position:sticky; top:0;
        font-weight:400; color:#fff; font-size:14px; white-space:nowrap;
        background-color:@btn-teal2;
        height:38px; padding:2px 8px;
        z-index:9;
    }
    th:hover {
        background-color:#0b6b86;
    }
    th.current {
        background-color:#0b6b86;
    }
    div {
        width:100%; height:100%;
        display:flex; align-items:center; justify-content:center;
    }

    th.current:after {
        content:""; display:block; width:100%; height:5px;
        position:absolute; left:0; bottom:0;
        background-color:#fb7061;
    }

    // 修正在 chrome 中 th 出現隨機白線的問題 (position:sticky 造成)
    th:before {
        content:""; display:block; width:1px; height:100%;
        position:absolute; right:-1px; top:0;
        background-color:@btn-teal2;
        // background-color:red;
        pointer-events:none;
    }
    th:last-child:before {
        display:none;
    }
}

tbody {
    tr:nth-child(even) {background-color:#f4f4f4;}
    td {
        // padding:10px 8px; height:34px;
        padding:8px; height:40px;
        border-bottom:1px solid #e5e5e5;

        small {font-size:11px;}
    }

    tr:hover {
        // color:#5d381c;
        background-color:#fff4d2;
    }
}

// 日期的樣式
td p.date {text-align:center;}
.dialogBox td p.date {display:block;}

td.created_at, td.updated_at, td.installed_at,
td.licensed_at, td.tested_at,
td.earliest_time, td.latest_time, td.checkin_time, td.last_updated {text-align:center;}

td span.date {white-space:nowrap; display:block;}
td span.date.d2 {margin-top:2px; font-size:11px;}

/* ----- Edge page 樣式設定 ----- */
.panel-edges {

    td {word-break:break-all;}
    td.installed_at, td.licensed_at {word-break:normal;}

}

/* ----- sorting button ----- */
.btn-sorting {
    cursor:pointer;
}
.btn-sorting svg {
    width:15px; height:15px; fill:#fff;
    position:relative; top:1px;
    margin-left:4px; opacity:.45;
}
.current .btn-sorting svg {opacity:1;}

.current .btn-sorting.asc svg {
    transform:rotate(180deg);
}

td .label {display:none;}
td .btn-sorting {display:none;}


/* ----- 表格中的按鈕 ----- */
td .btn {
    display:flex; justify-content:center; align-items:center;
    cursor:pointer; max-width:34px; margin:0 auto;
    position:relative; padding:5px 10px;
    font-size:13px; white-space:nowrap; color:#333;
    background-color:#fff; border:1px solid #d5d5d5;
    border-radius:6px;
    z-index:8;
}
td .btn:hover, td .btn.current {
    color:#fff;
    background-color:#333; border:1px solid #333;
}
td .btn.current {
    pointer-events:none;
}

td .btn.disabled {
    color:#aaa; opacity:.7;
    pointer-events:none;
}

/* ----- sorting table ----- */
.sorting-table {
    overflow:auto;
    border-left:1px solid #e5e5e5; border-right:1px solid #e5e5e5;
}

.sorting-table {

    // .filter-extra-info {display:none;}

    .fail {position:relative;}

    .fail {

        .more {cursor:pointer;}

        .detail {
            display:none;
        }
    }

    .fail:hover {

        .detail {
            display:block;
            position:absolute; left:0; bottom:20px; z-index:999;
            padding:4px 8px;
            text-align:left; white-space:nowrap; color:#333; line-height:1.3;
            background-color:#ffe2ac; border-radius:2px;
        }

    }

    // 裝置狀態的顏色標示
    td.active .strong {white-space:nowrap;}

}

// .sorting-table.filter {

//     .filter-extra-info {display:inline;}

// }

// 根據 tester_menu 設定值，決定顯示「工號 \ 身分證號 \ 手機號」其中一個欄位
// 1. 手機號
// 2. 工號
// 3. 身分證號
.tm-1 {
    .employee_number {display:none;}
    .id_card_number {display:none;}
    // .mobile {display:none;}
}

.tm-2 {
    // .employee_number {display:none;}
    .id_card_number {display:none;}
    .mobile {display:none;}
}

.tm-3 {
    .employee_number {display:none;}
    // .id_card_number {display:none;}
    .mobile {display:none;}
}


/* ----- API 說明頁 ----- */
body.document {
    thead th {
        position:relative; top:auto;
        padding:10px 12px; height:auto;
    }

    tr, td {border:1px solid #d1d1d1;}

    tbody td {
        height:auto; padding:10px 12px;
    }

    hr {
        margin:40px 0;
        border:none; border-bottom:1px solid #999;
    }
}

/* 顯示緊密的表格 */
table.compact {
    tbody td {
        height:auto; padding:4px 12px;
    }
}