@import url('../../css/variable.less');

.main-header {
    height:46px;
    display:flex; align-items:center;
}

.main-header {

    .logo, .user-control {flex-shrink:0;}

    .spacer {flex-grow:1;}

    .logo {
        display:block; position:relative;
        // width:151px; height:36px;
        font-size:0; line-height:0;
        background-size:auto 100%; background-repeat:no-repeat;
        cursor:pointer;
    }

}

// app 下載按鈕與選單
/*
#btnAppDwn {
    position:relative;
    margin-left:0; padding:0 20px;
    background-color:@btn-blue;
    border-radius:20px;
    display:none;

    .overlay {display:none;}

}
#btnAppDwn:hover {
    background-color:@btn-blue-hover;
}
#btnAppDwn svg {
    width:18px; height:18px;
}

#btnAppDwn.active {

    #appDwnMenu {
        top:39px;
        opacity:1; visibility:visible; pointer-events:auto;
    }

    .overlay {display:block;}

}

#appDwnMenu {
    position:absolute; right:0; top:30px;
    opacity:0; visibility:hidden; pointer-events:none;
    transition:400ms; transition-property:top, opacity, visibility;
}

#appDwnMenu {

    .innerwpr {
        position:relative; overflow:hidden;
        color:#333;
        background-color:rgba(255,255,255,.96);
        box-shadow:0 2px 5px rgba(0,0,0,.2); border-radius:6px;
        box-sizing:border-box;
        cursor:default;
    }

    .group+.group {border-top:1px solid #ddd;}

    a {
        color:#333; font-style:normal;
        display:flex; align-items:center;
    }

    .regular {padding:8px 15px;}
    .regular:hover {
        background-color:#f6f6f6;
    }
    .icon {
        width:28px; height:28px; display:block; margin-right:5px;
        display:flex; align-items:center; justify-content:center;
        background-color:#56595d;
        border-radius:50%;
    }
    .icon svg {
        fill:#fff; width:14px; height:14px;
        margin-right:0;
    }
    .regular:hover .icon {
        background-color:@btn-blue-hover;
    }

    .apk {
        padding:11px 15px;
    }
    .apk {
        h4 {font-size:14px; margin-bottom:3px;}
        p {font-size:12px;}
    }

    .file {
        padding:4px 10px; margin-top:10px;
        text-align:center; justify-content:center;
        border:1px solid #ddd; background-color:#eee;
        border-radius:4px;
    }
    .file:hover {
        color:#fff;
        background-color:@btn-blue-hover;
    }

}
*/

// 重要訊息公告
#btnSA {
    margin-left:5px; height:34px; padding:0 14px;
    display:flex; align-items:center; justify-content:center;
    font-size:14px; color:#111; white-space:nowrap;
    border-radius:20px;
    cursor:pointer;
}
#btnSA:hover {
    background-color:#eee;
}

.sa-title {

    .icon {
        width:20px; height:20px; margin-right:6px;
        display:flex; align-items:center; justify-content:center;
        background-color:#c74335; border-radius:50%;
        pointer-events:none;
    }

    .icon svg {
        width:12px; height:12px; fill:#fff;
    }

    svg.arrow {
        width:16px; height:16px; fill:#5c392b;
        margin-left:2px; position:relative; top:1px;
    }

}

#SABox {
    width:100%; height:100%; z-index:9999;
    position:fixed; left:0; top:0;
    display:flex; align-items:center; justify-content:center;
    background-color:rgba(0,0,0,.2);
    opacity:0; visibility:hidden; pointer-events:none;
    transition:400ms; transition-property:opacity, visibility;

    .box-content {
        width:360px; position:relative;
        background-color:#f9f7f4; border-radius:6px;
        overflow:hidden;
        box-shadow:0 0 6px rgba(0,0,0,.15);
        opacity:0; visibility:hidden; top:-30px;
        transition:400ms; transition-property:opacity, visibility, top;
    }

    h3 {
        padding:8px 20px; margin-bottom:5px;
        display:flex; align-items:center;
        font-size:17px;
        background-color:#ffb649; box-shadow:0 0 4px rgba(0,0,0,.2);

        .icon {width:22px; height:22px;}
    }

    .innerwpr {
        padding:20px;
        font-size:15px; line-height:1.6; color:#111;
    }

    p strong {
        padding:0 4px;
        font-weight:400;
        background-color:#97f6d9; border-radius:2px;
    }

    li {
        list-style-type:decimal; margin-left:18px;
    }
    li+li {margin-top:20px;}

    #SABoxClose {
        width:100%; height:36px; margin-top:20px;
        display:flex; align-items:center; justify-content:center;
        color:#fff;
        background-color:@btn-black-teal; border-radius:3px;
        cursor:pointer;
    }
    #SABoxClose:hover {background-color:@btn-black-teal-hover;}

}

#SABox.open {

    opacity:1; pointer-events:auto; visibility:visible;

    .box-content {
        opacity:1; visibility:visible; top:0;
    }

}

#extraBtnWpr {
    display:flex; align-items:center; flex-shrink:0;
    padding:0 12px;
}

#extraBtnWpr {

    .btn-control {
        margin:0;
        color:#333;
        background-color:transparent;
        border-radius:20px;

        svg {fill:#333;}
    }
    .btn-control:hover {
        background-color:#eee;
    }

    .btn-control:after {
        display:none;
    }

}