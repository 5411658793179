@import url('./variable.less');

body.unauth {
    background-color:#f2f0eb;
}

main.account {
    display:flex; justify-content:center; align-items:center;
    padding-left:0; transition-duration:0s;
}

.accountModal {
    padding:10px;
}

.accountModal {

    .innerwpr {
        // max-width:440px; min-width:320px;
        width:350px;
        position:relative; box-sizing:border-box;
        background-color:#fff;
        box-shadow:0px 2px 6px rgba(0,0,0,.08);
        border-radius:12px; overflow:hidden;
    }

    /* ----- header ----- */
    .header {
        width:auto; padding:30px 30px 0 30px;
        display:flex; align-items:center; justify-content:center;
        flex-direction:column;
        background-color:transparent;
    }
    .header {
        h2 {font-size:23px;}
        p {
            font-size:15px; text-align:center;
            opacity:.85;
        }

        .logo {
            display:block; position:relative;
            width:210px; height:54px; margin-bottom:20px;
            background-size:auto 100%; background-repeat:no-repeat;
            background-position:center center;
        }

        .rules-link {
            opacity:1; margin-top:5px;
            font-size:14px; color:#666;
        }
        .rules-link a {
            color:#666; padding-left:1px; margin:0 1px;
            border-bottom:1px dotted #444;
        }
        .rules-link a:hover {
            color:#000;
            border-bottom:1px dotted #000;
        }
    }

    /* ----- content ----- */
    .content {
        padding:20px 30px;
    }
    .signup .content {padding-top:14px;}
    .forgetpw .content {padding:10px 30px;}

    .txtlink {
        color:#616161; margin:0 4px;
        border-bottom:2px dotted #888;
    }
    .txtlink:hover {
        color:#333; border-bottom:2px dotted #444;
    }

    .sign-btn {
        height:44px; padding:0 15px; position:relative;
        display:flex; align-items:center; justify-content:center;
        color:#fff; line-height:1; font-size:15px;
        background-color:@btn-teal2;
        border-radius:5px; cursor:pointer;
    }
    .sign-btn+.sign-btn {margin-top:10px;}
    .sign-btn:hover {
        background-color:@btn-teal2-hover;
    }

    .sign-btn.black {background-color:@btn-black-teal;}
    .sign-btn.black:hover {background-color:@btn-black-teal-hover;}

    .sign-btn svg {
        width:18px; height:18px; margin-right:8px;
        fill:#fff;
    }

    .inputwpr {

        .flexbox {
            display:flex; align-items:center;
            height:26px; margin-bottom:2px;
        }

        .input-hint {font-size:14px;}

        .error {
            p {
                margin-left:5px;
                font-size:14px; color:@btn-alert;
            }
        }

    }
    .inputwpr+.inputwpr {margin-top:4px;}

    .sign-btn {

        .hint {
            display:none; cursor:pointer;
            position:absolute; left:0; top:-56px;
            padding:4px 10px;
            font-size:14px; color:#333; line-height:1.4;
            background-color:#ffcf80; border-radius:4px;

            strong {
                display:inline-block;
                padding:2px 12px; margin-left:3px;
                font-weight:normal;
                background-color:#ff9859; border-radius:10px;
                pointer-events:none;
            }
        }
        .hint:before {
            content:""; display:block; width:100%; height:6px;
            position:absolute; left:0; bottom:-6px;
            // background-color:pink; opacity:.5;
        }
        .hint:after {
            content:""; display:block; width:0; height:0;
            position:absolute; left:10px; bottom:-10px;
            border-style:solid; border-width:10px 10px 0 10px;
            border-color:#ffcf80 transparent transparent transparent;
        }
    }
    // .sign-btn:hover .hint {display:block;}

    /* ----- footer ----- */
    .footer {
        width:auto; padding:10px 30px 25px 30px;
        display:flex; align-items:center; justify-content:space-between;
        font-size:14px; color:#777; line-height:1.5;
        background-color:transparent;
    }
    .footer.center {
        justify-content:center;
    }

    .footer-btn {
        padding:7px 13px; min-width:40px;
        color:#333; white-space:nowrap; text-align:center;
        background-color:#f1f1f1; border-radius:20px;
    }
    .footer-btn:hover {
        background-color:#e6e6e6;
    }

    .linkgroup {display:flex; align-items:center;}
    .linkgroup .hr {
        width:6px; height:16px;
    }

    .auth-note {
        position:relative; margin-right:15px;
        display:flex; justify-content:center; align-items:center;
        // font-size:14px;
    }

    .auth-note {

        .overlay {
            display:none; z-index:10000;
        }

        .note-content {
            position:absolute; left:-10px; bottom:40px;
            width:310px; z-index:10001;
            background-color:#f1f1f1; border-radius:6px;
            display:none;
        }
        p {
            padding:20px 18px 22px 18px;
            line-height:1.5; color:#444;
        }
    }

    .auth-note.active {

        .overlay {display:block;}

        .note-content {display:block;}

        #authNoteTrigger {color:#333;}

        #authNoteTrigger {

            .icon {background-color:#333;}
            .icon:after {display:block;}
        }

    }

    #authNoteTrigger {
        cursor:pointer;
        display:flex; justify-content:center; align-items:center;
        flex-shrink:0;
        color:#555; white-space:nowrap; line-height:1.2;
    }
    #authNoteTrigger:hover {

        color:#333;

        .icon {background-color:#333;}
    }

    #authNoteTrigger {

        .icon {
            width:22px; height:22px; pointer-events:none;
            position:relative; margin-right:5px;
            display:flex; justify-content:center; align-items:center;
            background-color:#555;
            border-radius:50%;
        }

        .icon:after {
            content:""; display:none; width:0; height:0;
            position:absolute; left:50%; top:-18px; margin-left:-12px;
            border-style:solid; border-width:12px 12px 0 12px;
            border-color:#f1f1f1 transparent transparent transparent;
        }

        svg {
            width:12px; height:12px;
            fill:#fff;
        }
        .text {pointer-events:none;}
    }


    /* ----- Email 登入 ----- */
    .email-block {margin-top:22px;}

    .signup .email-block,
    .forgetpw .email-block,
    .resetpw .email-block {margin-top:0;}

    .email-block {

        input {
            width:290px; height:40px; margin:0 auto;
            background-color:#ebebeb;
        }

        .sign-btn {margin-top:10px;}

    }

    /* ----- 社群帳號 ----- */
    .social-block {margin-top:22px;}


    /* ----- 帶分隔線的小標題 ----- */
    .hr-title {
        display:flex; align-items:center;
        color:#666;
    }
    .hr-title:before, .hr-title:after {
        content:""; display:block; height:1px;
        flex:1; margin-top:1px;
        background-color:#dadada;
    }
    .hr-title .text {
        margin:0 10px;
        font-size:14px;
    }

    // .login .hr-title {margin-bottom:0;}
    .forgetpw .hr-title .text {font-size:15px;}

    .social-block .hr-title,
    .resetpw .hr-title.success {
        margin-bottom:10px;
    }


    // 回饋訊息
    #feedbackMessage, .login-msg {
        margin-top:10px;
        font-size:14px;
    }
    #feedbackMessage p {text-align:center;}
    #feedbackMessage p.error {color:@btn-alert;}

    // 登入時的訊息提示
    .login-msg {
        p {text-align:center;}
        p.error {color:@btn-alert;}
    }

    // 重設密碼的回饋訊息
    .forgetpw {

        #feedbackMessage {
            display:flex; align-items:center;
            margin-top:15px;
        }

        #feedbackMessage svg {
            width:36px; height:36px; margin-right:8px;
        }

        #feedbackMessage svg.success {fill:#058ea7;}
        #feedbackMessage svg.fail {fill:#e24c3d;}
        #feedbackMessage svg.small {width:26px; height:26px;}

        #feedbackMessage p {
            text-align:left; line-height:1.5;
        }

    }

} // end of accountModal

// 日文版
body.jp {

    .accountModal .login {

        .footer {
            flex-direction:column; justify-content:center;
            padding-top:0;
        }

        .auth-note {
            align-self:flex-start;
            margin-right:0; margin-bottom:15px;
        }

    }

    .accountModal .signup {

        .footer {justify-content:center;}

    }

}