@import '../../css/variable.less';

/* --- dialog box: common --- */
.dialogBox {
    display:flex; justify-content:center; align-items:center;
    position:fixed; width:100%; height:100vh; left:0; top:0;
    visibility:hidden; pointer-events:none;
    z-index:9999;
}

.dialogBox {

    .dialog-overlay,.dialog-container {
        visibility:hidden; opacity:0;
        pointer-events:none;
    }

    .dialog-overlay {
        position:fixed; left:0; top:0;
        width:100%; height:100%;
        background-color:@overlay-dark;
        transition-duration:1000ms;
        transition-property:opacity,visibility;
    }

    .loading {
        min-height:300px;
        display:flex; justify-content:center; align-items:center;
    }

    .dialog-container {
        position:relative; margin:0 10px; padding:20px 22px;
        top:-50px; // 關閉狀態 top 值為負的
        width:100%; max-width:480px; // 預設最大寬度 480px
        max-height:calc(100% - 60px); // 預設高度
        text-align:left; color:#333;
        // background-color:#f3f3f3;
        background-color:#f6f5f1;
        border-radius:6px 6px 5px 5px;
        box-shadow:0 2px 6px rgba(0,0,0,.2);
        transition-duration:400ms;
        transition-property:opacity,visibility,top;

        display:flex; flex-direction:column;
    }

    .title {
        position:relative; margin:-20px -22px 14px -22px; padding:14px 22px;
        display:flex; align-items:center;
        font-size:17px; line-height:1.2; font-weight:400;
        // background-color:#283346;
        background-color:#fdfdfc; border-radius:5px 5px 0 0;
        box-shadow:0 0 6px rgba(0,0,0,.1);
        z-index:200;
    }

    svg.title-icon {
        width:19px; height:19px;
        fill:#333; margin-right:10px;
        position:relative;
    }

    #deviceStoreBatchBox .title svg {top:1px;} // 新增酒測器

    .hint {
        margin-bottom:10px;
        font-size:14px;

        strong {
            padding:0 4px;
            font-weight:normal;
            background-color:@hint-yellow;
        }
    }

    // fullwidth hint 樣式
    .dialog-container.fullwidth {

        .title {margin-bottom:0;}

        .hint {
            margin:0 -22px 12px -22px; padding:10px 22px;
            min-height:24px; display:flex; align-items:center;
            font-size:15px; line-height:1.5;
            background-color:#eaeae6; box-shadow:0 0 4px rgba(0,0,0,.1);
            border-bottom:1px solid #d6d6d6;
        }

    }

    .hint .slot+.slot {margin-left:15px;}

    .content, .innerwpr {
        position:relative;
    }

    .content {flex:1; overflow:hidden;}

    // 不使用 js 捲軸，改用系統內建捲軸
    .content.no-scroll {
        overflow-y: auto !important;
        margin: 0 -22px; padding:0 22px;
    }

    .innerwpr {
        width:100%; padding:4px 0; box-sizing:border-box;

        p+p, p+ul, ul+p {margin-top:10px;}

        ul {padding-left:14px;}
        ul li {list-style:disc;}

        strong {
            padding:1px 4px; margin:0 3px;
            font-weight:400;
            background-color:#ffe4af; border-radius:3px;
        }

        img {margin:0 auto;}
    }

    .btnwpr {
        display:flex; align-items:center;
        margin-top:18px;
    }

    .btnwpr {

        .btn {
            flex:1; flex-shrink:0; height:40px; padding:0 12px;
            display:flex; align-items:center; justify-content:center;
            font-size:15px; text-align:center; line-height:normal;
            color:#fff;
            background-color:@btn-black-teal;
            border-radius:4px;
            cursor:pointer;
        }
        .btn:hover {
            background-color:@btn-black-teal-hover;
        }

        .btn.disabled {
            pointer-events:none;
        }

        .btn.confirm {flex-grow:1.5;}

        .btn.cancel, .btn.white {
            flex-grow:1;
            color:#333;
            background-color:@btn-white-teal;
            box-shadow:inset 0 0 0 1px #e6e6e6;

            svg {fill:#333;}
        }
        .btn.cancel:hover, .btn.white:hover {
            color:#fff;
            background-color:@btn-white-teal-hover;
            box-shadow:none;

            svg {fill:#fff;}
        }

        .btn+.btn {margin-left:10px;}

        .btn svg {
            width:16px; height:16px; margin-right:5px;
            fill:#fff; position:relative;
            overflow:hidden;
        }

    }

    .alert {
        display:flex; align-items:center;
        color:#e84e4a;
    }
    .alert svg {
        width:24px; height:24px; margin-right:5px;
        fill:#e84e4a; position:relative; top:-1px;
        overflow:hidden;
    }

    textarea {
        width:100%; padding:8px 12px; min-height:200px;
        box-sizing:border-box; resize:none;
        font-size:15px; line-height:normal;
        border:1px solid #cacaca; border-radius:2px;
        background-color:rgba(0,0,0,.05);
        // box-shadow:inset 0 0 10px rgba(0,0,0,.05);
        outline:none;
    }

    .feedback-content {
        padding:6px 10px; margin:6px 0 -8px 0;
        font-size:14px; line-height:1; text-align:center; color:#856519;
        background-color:#fff5cb; border:1px solid #f0de97;
        border-radius:2px;
    }

    .feedback-content.warning {
        // color:#856519;
        // background-color:#fff5cb; border:1px solid #f0de97;
        color:#333;
        background-color:#fee28f; border:1px solid #ebca78;
    }

    .feedback-content.fail {
        color:#fff0ef;
        background-color:#fc6a5f;
    }

    .error-msg {

        p {font-size:15px; text-align:left; word-break:break-all;}
        p+p {margin-top:8px;}

    }

}

/* --- dialog box: show --- */
.dialogBox.show {
    visibility:visible; pointer-events:auto;
}

.dialogBox.show {

    .dialog-overlay,.dialog-container {
        visibility:visible; opacity:1;
    }

    .dialog-container {
        top:0; pointer-events:auto;
    }
}

/* --- dialog box: 特別設定 --- */

// overlayscrollbars
.os-scrollbar-vertical {
    padding-left:0 !important;
    padding-right:0 !important;
    width:6px !important;
}

// 公司列表的授權碼對話框
#comLicense .dialog-container {max-width:1200px;}

// 批次新增酒測器時如果序號已經存在，詢問是否轉移至目前站點的對話框
#deviceStoreBatchBox.exist .dialog-container {max-width:650px;}

// Edge 版本更新紀錄的對話框
#edgeLogs .dialog-container {max-width:500px;}

// ----- 圖片操作說明 --- //
// 批次新增受測者 API 未啟用提示
#batchStoreBox.api-off .title,
// 管理權限與帳號綁定說明
#bindExplainBox .title,
// 新增受測者後的手動更新資料庫說明
#renewDbExplainBox .title
{
    background-color:#f3f3f3;
    box-shadow:none;
    border-bottom:1px solid #e9e9e9;
}


/* --- 行動裝置上的一些調整 --- */
body.mobile .dialogBox {

    height:100%;

}

