@import '../../css/variable.less';

#changePwBox {

    .dialog-container {
        width:350px; box-sizing:border-box;
        overflow:hidden;
    }

    .title {margin-bottom:0;}

    .content, .innerwpr {
        position:static;
    }

    .content {
        padding:20px 22px;
        border-bottom:1px solid #ddd;
    }
    .innerwpr {padding:0;}

    .inputwpr+.inputwpr {margin-top:10px;}

    .inputwpr {

        label {
            display:inline-block; margin-bottom:2px;
            font-size:15px;
        }

        input {
            width:100%; height:40px;
            margin:0 auto; padding-right:40px;
            box-sizing:border-box;
        }
    }

    .btnwpr {
        padding:18px 22px 0 22px;
        margin:0 -22px;
        border-top:1px solid #fff;
    }

    .inputbox {
        position:relative;
    }

    .visibility-toggle {
        width:40px; height:40px;
        position:absolute; right:0; top:0;
        display:flex; align-items:center; justify-content:center;
        border-left:1px dotted #aaa;
        cursor:pointer;

        .hide {display:none;}

        svg {
            width:24px; height:24px;
            fill:#3a3a3a;
        }
    }
    .visibility-toggle.visible {
        .show {display:none;}
        .hide {display:block;}
    }

    // 錯誤訊息提示
    #CPErrMsg {

        p {
            padding:6px 15px; margin-top:15px;
            font-size:15px; line-height:1.4;
            background-color:#fde6e2;
            border-left:6px solid #e25e68;
            // background-color:#fff;
            border-radius:3px;
            box-shadow:0 1px 2px rgba(0,0,0,.1);
        }
    }

    // 完成對話框
    #CPsuccess {
        z-index:300; position:absolute; left:0; top:0;
        width:100%; height:100%;
        display:flex; align-items:center; justify-content:center;
        // background-color:rgba(246,245,241,.8);
        // background-color:rgba(0,0,0,.5);
        pointer-events:none;

        .innerwpr {
            box-sizing:border-box;
            width:320px; padding:20px;
            text-align:center;
            background-color:#fff; border-radius:6px;
            box-shadow:0 2px 6px rgba(0,0,0,.2);
            // box-shadow:0 2px 6px rgba(0,0,0,.2);
            position:relative;
            top:-50px; visibility:hidden; opacity:0;
            transition-duration:400ms;
            transition-property:opacity,visibility,top;
        }

        p {text-align:center;}

        .btn {
            flex:1; flex-shrink:0; height:40px; padding:0 12px;
            margin-top:20px;
            display:flex; align-items:center; justify-content:center;
            font-size:15px; text-align:center; line-height:normal;
            color:#fff;
            background-color:@btn-black-teal;
            border-radius:4px;
            cursor:pointer;
        }
        .btn:hover {
            background-color:@btn-black-teal-hover;
        }
        .btn svg {
            width:16px; height:16px; margin-right:5px;
            fill:#fff; position:relative;
            overflow:hidden;
        }

        .icon {
            width:80px; height:80px; margin:20px auto 0 auto;
            display:flex; align-items:center; justify-content:center;
            background-color:#19c1c1;
            border-radius:40px;

            svg {
                width:30px; height:30px;
                fill:#fff;
            }
        }
    }
}

#changePwBox.success {

    .inputwpr, .btnwpr {
        opacity:.2;
    }

    #CPsuccess {
        pointer-events:auto;

        .innerwpr {
            top:0; visibility:visible; opacity:1;
        }
    }

}