body.landing {
    min-width:300px;

    header.header {
        min-width:0; position:fixed;   

        .spacer {display:block;}
               
    }

    main {padding-left:0; padding-top:46px;}
    footer.footer {display:none;}

}